import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import {
  checkAccountNameExistAction,
  generateVerificationCodeAction,
  getUerAccountsDetailsAction,
  lastLoginTrackAction,
  saveAccountNameAction,
  verifyVerificationCodeAction,
} from "../Actions/CommonActions";
import {
  LoadingSlides,
  checkRolesAccess,
  isVodexLoggedin,
} from "../CommonComponents/Common";
import { clearProjectReducer } from "../Projects/Store/projects.action";
import { clearAudienceReducer } from "../ProjectSection/AudienceSection/Store/Audience.action";
import { clearCallSettingReducerAction } from "../ProjectSection/CallSettings/Store/callSetting.action";
import {
  clearAccountReducerAction,
  getEncryptionPublicKeyAction,
  logoutAction,
} from "../Store/Account/Account.action";
import PhoneInput from "../Common/Inputs/PhoneInput";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";
import { clearCampaignReducerAction } from "../ProjectSection/CampaignSection/Store/Campaign.action";
import {
  getDecryptedItem,
  setEncryptedItem,
} from "../../../utils/localStorageHelper";

interface VerificationResponse {
  status: number;
  msg: string;
  errMsg?: string;
}

function AssignAccountName() {
  const history = useHistory();
  const dispatch = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: { accountRoles, userDetails, selectedAccount, publicKey },
  } = reducer;

  const locSearch = getDecryptedItem("account-id");

  const [resend, setResend] = useState(0);
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);
  const [showall, setShowall] = useState(false);
  const [timeLeft, setTimeLeft] = useState(59);
  const [showInput, setShowInput] = useState(false);
  const [hide, setHide] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [reload, setReload] = useState(false);
  const [showSupportText, setShowSupportText] = useState(false);
  const [state, setState] = useState({
    name: "",
    errName: false,
    alreadyExist: false,
    alreadyExistValid: false,
    count: 0,
    verificationNumber: "",
    spin: false,
    charErr: false,
  });

  const {
    name,
    errName,
    alreadyExist,
    count,
    spin,
    verificationNumber,
    charErr,
  } = state;

  useEffect(() => {
    const token = getDecryptedItem("token");
    if (!token) {
      history.push("/");
    }
    const hideLoc = getDecryptedItem("hide");
    if (hideLoc) setHide(hideLoc);

    const verifiedPhoneNumber = getDecryptedItem("verifiedPhoneNumber");
    if (verifiedPhoneNumber) {
      setReload(true);
      setState((prevState) => ({
        ...prevState,
        name: verifiedPhoneNumber.accName || "",
      }));
    } else {
      setReload(false);
    }

    if (publicKey) getUserDetails();
  }, [publicKey]);

  async function getUserDetails() {
    await dispatch(getUerAccountsDetailsAction());
    setDataLoaded(true);
  }

  useEffect(() => {
    const accountName = selectedAccount?.accountName;
    const role = checkRolesAccess(accountRoles);
    if (accountName) {
      if (role === "dashboard") {
        history.push("/account/dashboard");
      } else if (role === "project") {
        history.push("/account/projects");
      }
    }
  }, [accountRoles]);

  useEffect(() => {
    const isSpecialChar = checkSpecialChar(name);

    if (count > 0) {
      if (name === " ") {
        setState((prev) => ({ ...prev, errName: true }));
      } else {
        setState((prev) => ({ ...prev, errName: false }));
      }
    }

    if (isSpecialChar) {
      setState((prev) => ({ ...prev, charErr: true }));
      return;
    } else {
      setState((prev) => ({ ...prev, charErr: false }));
    }

    const delayDebounceFn = setTimeout(async () => {
      if (name?.trim()) {
        const res: any = await dispatch(checkAccountNameExistAction(name));
        setState((prev) => ({
          ...prev,
          alreadyExist: !res?.data.available,
        }));
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [name]);

  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsRunning(false);
    }
    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  useEffect(() => {
    const init = async () => {
      const lastLoginObj = {
        accountUrl: selectedAccount.accountUrl,
        userId: userDetails._id,
        accountId: selectedAccount._id,
      };
      await dispatch(lastLoginTrackAction(lastLoginObj));
    };
    if (dataLoaded && selectedAccount && userDetails && publicKey) {
      init();
    }
  }, [dataLoaded, selectedAccount, userDetails, publicKey]);

  const handleSave = async (e, nav) => {
    e.preventDefault();
    setShowInput(true);

    if (charErr) {
      swalWarningMessage("Special Characters are Not Allowed.");
      return;
    }
    if (!name?.trim()) {
      setState((prev) => ({ ...prev, errName: true }));
      return;
    }
    if (name?.trim()?.length > 25) {
      swalWarningMessage("Account name can't be more than 25 characters");
      return;
    }

    if (!alreadyExist) {
      setState((prev) => ({ ...prev, spin: true }));
      let currency = "usd";
      if (verificationNumber.startsWith("91")) {
        currency = "inr";
      }
      console.log(currency);

      const obj = {
        accountName: name,
        accountId: locSearch,
        currency,
      };
      const res: any = await dispatch(saveAccountNameAction(obj, history));
      if (res?.status !== 200) {
        setState((prev) => ({
          ...prev,
          spin: false,
        }));
        return;
      }
      localStorage.removeItem("verifiedPhoneNumber");
      localStorage.removeItem("hide");
      if (nav) {
        history.push("/account/dashboard");
      }
      setState((prev) => ({ ...prev, spin: false }));
    }
  };

  const checkSpecialChar = (str) => {
    const specialCharRegex = /[ !@#$%^&*(),.?":{}|<>;/'\[\]=+\-_`~\\]/g;
    return specialCharRegex.test(str);
  };

  const onChange = async (e) => {
    e.persist();
    setState((prev) => ({ ...prev, name: e.target.value, count: 1 }));
  };

  const generateVerificationCode = async (e) => {
    if (charErr) {
      swalWarningMessage("Special Characters are Not Allowed.");
      return;
    }
    e.preventDefault();
    if (!name?.trim()) {
      setState((prev) => ({ ...prev, errName: true }));
      return;
    }
    if (name?.trim()?.length > 25) {
      swalWarningMessage("Account name can't be more than 25 characters");
      return;
    }

    if (verificationNumber === "" || verificationNumber.length <= 8) {
      swalWarningMessage("A phone number is required.");
    } else {
      setTimeLeft(59);
      setIsRunning(true);
      setCode("");
      setResend((prev) => prev + 1);
      setState((prev) => ({ ...prev, spin: true }));
      const obj = {
        phoneNumber: `+${verificationNumber}`,
      };
      const res = (await dispatch(
        generateVerificationCodeAction(obj)
      )) as unknown as VerificationResponse;

      if (res?.status !== 200) {
        setShowButton(false);
        setResend(0);
        setState((prev) => ({ ...prev, spin: false }));
        return;
      }
      setShowSupportText(true);
      setState((prev) => ({ ...prev, spin: false }));
      setShowButton(true);
    }
  };

  const preventInvalidInput = (e) => {
    if (
      e.key === "e" ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "E" ||
      e.key === "."
    ) {
      e.preventDefault();
    }
  };

  const verifyVerification = async () => {
    const localObj = {
      accName: name,
      verificationNumber,
    };
    setEncryptedItem("verifiedPhoneNumber", localObj);

    if (code.length < 4) {
      swalWarningMessage("Please enter valid code");
      localStorage.removeItem("verifiedPhoneNumber");
      return;
    }
    setState((prev) => ({ ...prev, spin: true }));
    let obj = {
      otp: code,
    };
    let res: any = await dispatch(verifyVerificationCodeAction(obj, history));
    if (res !== 200) {
      setShowall(false);
      setShow(false);
      setShowButton(true);
      setShowInput(false);
      setReload(false);
      setState((prev) => ({ ...prev, spin: false }));
      localStorage.removeItem("verifiedPhoneNumber");
      return;
    }
    if (res === 200) {
      setShowall(true);
      setShow(true);
      setShowButton(false);
      setShowInput(true);
      setReload(true);
    }
    setState((prev) => ({ ...prev, spin: false }));
    setHide(true);
    setEncryptedItem("hide", true);
  };

  const handleLogout = async () => {
    await dispatch(clearAccountReducerAction());
    await dispatch(clearProjectReducer());
    await dispatch(clearCallSettingReducerAction());
    await dispatch(clearAudienceReducer());
    await dispatch(clearCampaignReducerAction());
    await dispatch(logoutAction(history));
    await dispatch(getEncryptionPublicKeyAction());
    history.push("/");
  };

  return (
    <Grid container className="auth-container otp-verification">
      <Grid item md={6.5} className="left-section otp-verification-left"></Grid>

      <Grid
        item
        xs={12}
        md={5.5}
        className="right-section otp-verification-right"
      >
        <Box
          width={"100%"}
          className="main-container otp-verification-container"
        >
          <span
            title="Logout"
            className="new-logout"
            style={
              isVodexLoggedin()
                ? { backgroundColor: "#fd7e14", color: "whitesmoke" }
                : { backgroundColor: "white", color: "#737373" }
            }
            onClick={handleLogout}
          >
            <i className="fas fa-sign-out-alt fa-sm fa-fw"></i>
          </span>
          <Box>
            <Typography className="font-28-700-grey" mb={1}>
              Verify Your Account
            </Typography>
            {!showButton && (
              <Box mb={2}>
                <Box>
                  <Typography className="font-14-grey mb-1">
                    Account Name
                  </Typography>
                  <TextField
                    name="email"
                    value={name}
                    onChange={onChange}
                    required={true}
                    variant="outlined"
                    fullWidth
                    placeholder="Enter your account name"
                    sx={commonStyles.inputField}
                  />
                </Box>
                {errName && (
                  <Typography color="red" mt={1}>
                    Account name is required
                  </Typography>
                )}

                {charErr && (
                  <Typography color="red" mt={1}>
                    Special Characters not allowed
                  </Typography>
                )}

                {alreadyExist && (
                  <Typography color="red" mt={1}>
                    This account name is not available
                  </Typography>
                )}
              </Box>
            )}
            {isVodexLoggedin() ? (
              <>
                <Box mb={2}>
                  <Typography className="font-14-grey mb-1">
                    Phone Number
                  </Typography>
                  <PhoneInput
                    disabled={showInput || reload || showButton}
                    value={
                      verificationNumber ||
                      getDecryptedItem("verifiedPhoneNumber")
                        ?.verificationNumber
                    }
                    handleChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        verificationNumber: e.replace(/\D/g, ""),
                      }));
                    }}
                  />
                </Box>

                {showButton && (
                  <Box>
                    <Box mb={2}>
                      <Typography className="font-14-grey mb-1">
                        Code
                      </Typography>
                      <TextField
                        type="number"
                        name="email"
                        value={code}
                        onChange={(e) => {
                          e.persist();
                          if (e.target.value.length <= 4) {
                            setCode(e.target.value);
                          }
                        }}
                        onKeyDown={preventInvalidInput}
                        required={true}
                        variant="outlined"
                        fullWidth
                        placeholder="Enter 4-digit verification code"
                        sx={commonStyles.inputField}
                        autoComplete="off"
                      />
                    </Box>

                    <Box
                      className="flex-xy-center"
                      flexDirection={"column"}
                      gap={1}
                    >
                      <Typography
                        onClick={() => {
                          setShowButton(false);
                          setResend(0);
                          setShowInput(false);
                        }}
                        className="font-14-grey"
                        sx={{
                          "&:hover": {
                            textDecoration: "underline",
                            cursor: "pointer",
                          },
                        }}
                      >
                        Wrong Number ?
                      </Typography>

                      {resend <= 2 ? (
                        timeLeft === 0 ? (
                          <Typography
                            onClick={generateVerificationCode}
                            className="font-14-grey"
                            sx={{
                              "&:hover": {
                                textDecoration: "underline",
                                cursor: "pointer",
                              },
                            }}
                          >
                            Resend Code
                          </Typography>
                        ) : (
                          <Typography className="font-14-grey">
                            <span>Resend Code</span>{" "}
                            {timeLeft !== 0 && `in 00:${timeLeft} seconds`}
                          </Typography>
                        )
                      ) : (
                        <Typography>
                          Didn't receive the code ?
                          <span
                            style={{ cursor: "pointer" }}
                            className="underlined"
                            onClick={(e) => {
                              handleSave(e, true);
                            }}
                          >
                            Click here to continue
                          </span>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )}

                {(show || reload) && (
                  <Button
                    onClick={(e) => handleSave(e, false)}
                    disabled={spin}
                    variant="contained"
                    sx={commonStyles.loginButton}
                    fullWidth
                  >
                    {spin ? <LoadingSlides bgColor="white" /> : "Save"}
                  </Button>
                )}

                {!showButton && !showall && !reload && (
                  <Button
                    onClick={generateVerificationCode}
                    disabled={spin || alreadyExist}
                    variant="contained"
                    sx={commonStyles.loginButton}
                    fullWidth
                  >
                    {spin ? (
                      <LoadingSlides bgColor="white" />
                    ) : (
                      "Send Verification Code"
                    )}
                  </Button>
                )}

                {showButton && !showall && (
                  <Button
                    onClick={verifyVerification}
                    disabled={spin}
                    variant="contained"
                    sx={commonStyles.loginButton}
                    fullWidth
                  >
                    {spin ? (
                      <LoadingSlides bgColor="white" />
                    ) : (
                      "Verify Account"
                    )}
                  </Button>
                )}
              </>
            ) : (
              <Button
                onClick={(e) => handleSave(e, false)}
                disabled={spin}
                variant="contained"
                sx={commonStyles.loginButton}
                fullWidth
              >
                {spin ? <LoadingSlides bgColor="white" /> : "Save"}
              </Button>
            )}
            {showSupportText && hide === false
              ? isVodexLoggedin() && (
                  <>
                    <Typography textAlign="center" mt={2}>
                      Didn't received code?
                    </Typography>
                    <Typography textAlign="center">
                      Please contact support at {""}
                      <a href="mailto:support@vodex.ai">support@vodex.ai</a>
                    </Typography>
                  </>
                )
              : null}
          </Box>
        </Box>{" "}
      </Grid>
    </Grid>
  );
}

export default AssignAccountName;
