import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, PAGE_SIZE_50 } from "../CommonComponents/Common";
import { getAudienceAction } from "../ProjectSection/AudienceSection/Store/Audience.action";
import TabsSection from "./TabsSection";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";

const AudienceForm = ({ obj }) => {
  const dispatch = useDispatch();
  const { projectId }: any = useParams();
  let { formState, setFormState, formobj, setState, state } = obj;
  const { audienceData } = formState;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      file: "",
    }));

    return () => {
      setFormState(formobj);
      setState((prev) => ({
        ...prev,
        editedAudience: {},
      }));

      const init1 = async () => {
        const obj = {
          pageIndex: 1,
          pageSize: PAGE_SIZE_50,
          searchText: state?.searchText,
        };
        await dispatch(getAudienceAction(projectId, obj));
      };
      init1();
    };
  }, []);

  return (
    <Box>
      <Box flex={1}>
        <Typography className="font-14-grey mb-1">Name</Typography>
        <TextField
          name="name"
          value={formState.name.value}
          disabled={!isEmpty(state?.editedAudience)}
          onChange={(e) =>
            setFormState({
              ...formState,
              name: { error: "", value: e.target.value },
            })
          }
          variant="outlined"
          fullWidth
          placeholder="Enter the audience list name it can be “Doctor’s List”"
          sx={commonStyles.inputField}
        />
      </Box>
      <TabsSection
        formState={formState}
        setFormState={setFormState}
        state={state}
        setState={setState}
        audienceData={audienceData}
      />
    </Box>
  );
};

export default AudienceForm;
