import React, { useEffect, useState } from "react";
import { Box, Drawer, IconButton } from "@mui/material";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  swalErrorMessage,
  swalWarningMessage,
} from "../../../Constant/swalMessage";
import { isEmpty, PAGE_SIZE_50 } from "../CommonComponents/Common";
import {
  getCallHistoryAction,
  getCallHistoryCountAction,
} from "../ProjectSection/CampaignSection/Store/Campaign.action";
import CallHistoryHeader from "./CallHistoryHeader";
import { ReactComponent as RedirectIcon } from "../../../assets/icons/external-link.svg";
import chroma from "chroma-js";
import CallTranscriptModal from "./CallTranscriptModal";
import DynamicTable from "../CommonComponents/Table/DynamicTable";
import CallHistoryPopover from "./CallHistoryPopover";
import CallLogs from "../ProjectSection/CampaignSection/CallHistory/CallLogs";
import FilterBody from "./Filter/FilterBody";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactAudioPlayer from "react-audio-player";
import CommonPopup from "../CommonComponents/PopUpCards/CommonPopup";
import { ReactComponent as ExportFileIcon } from "../../../assets/Images/ExportPopupIcon.svg";
import StatusButton from "../Common/Buttons/StatusButton";
import BaseButton from "../Common/Buttons/BaseButton";
import { shortenString } from "../../../Constant/helper";
import moment1 from "moment-timezone";
import "../../../assets/css/page/CallHistory.scss";
import { getDecryptedItem } from "../../../utils/localStorageHelper";

const CallHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = moment().format("YYYY-MM-DD");
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let Stalker = locSearch.get("stalker");
  const timezone = moment1.tz.guess();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showCallTranscriptModal, setShowCallTranscriptModal] = useState(false);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [searchText, setSearchText] = useState(undefined);
  const [isReady, setIsReady] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterNameFromDropdown, setFilterNameFromDropdown] = useState("");
  const [dateRange, setDateRange] = useState([
    new Date(currentDate),
    new Date(currentDate),
  ]);
  const [exportLoad, setExportLoad] = useState(false);
  const [startDate, endDate] = dateRange;

  const reducer: any = useSelector((state) => state);
  const {
    AccountReducer: { constantData, selectedAccount },
    CampaignReducer: { callHistoryDetails, callHistoryDetailsTotalCounts },
  } = reducer;

  const [call_uuid, setCall_uuid] = useState("");
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState(
    getDecryptedItem("callHistorySearchTerm") || ""
  );
  const [exportPopup, setExportPopup] = useState(false);

  const [state, setState] = useState<any>({
    filterDrawer: false,
    trackEnabledRecording: [],
    pageIndex: currentPage,
    durationFilter: "",
    statusFilter: [],
    outComefilter: [],
    min: "",
    max: "",
    selectedProjectId: "",
    campaignDataList: [],
  });

  useEffect(() => {
    const appliedFilters = getDecryptedItem("savedFilters") || {};
    const appliedFiltersLoc = appliedFilters?.[selectedAccount?._id];

    setFilterName(appliedFiltersLoc?.filterName || "");
    setDateRange([
      appliedFiltersLoc?.startDate
        ? new Date(appliedFiltersLoc?.startDate)
        : new Date(currentDate),
      appliedFiltersLoc?.endDate
        ? new Date(appliedFiltersLoc?.endDate)
        : new Date(currentDate),
    ]);

    setState((prevState) => ({
      ...prevState,
      durationFilter: appliedFiltersLoc?.durationFilter || "",
      outComefilter: appliedFiltersLoc?.outcomeFilters || [],
      statusFilter: appliedFiltersLoc?.statusFilter || [],
      min: appliedFiltersLoc?.customFilter
        ? appliedFiltersLoc?.customFilter.split("-")[0] || ""
        : "",
      max: appliedFiltersLoc?.customFilter
        ? appliedFiltersLoc?.customFilter.split("-")[1] || ""
        : "",
      selectedProjectId: appliedFiltersLoc?.selectedProjectId || "",
    }));
  }, [filterNameFromDropdown]);

  useEffect(() => {
    if (callHistoryDetails?.length === 0) setLoading(true);
    if (isReady) fetchCallHistory();
  }, [currentPage, searchText, isReady]);

  useEffect(() => {
    if (callHistoryDetailsTotalCounts) {
      setTotalPages(Math.ceil(callHistoryDetailsTotalCounts / PAGE_SIZE_50));
    }
  }, [currentPage, searchText, callHistoryDetailsTotalCounts]);

  useEffect(() => {
    if (callHistoryDetails) {
      setState((prev) => ({
        ...prev,
        campaignDataList: callHistoryDetails?.map((item) => item?.combinedData),
      }));
    }
  }, [callHistoryDetails]);

  const {
    filterDrawer,
    trackEnabledRecording,
    durationFilter,
    statusFilter,
    outComefilter,
    selectedProjectId,
    campaignDataList,
  } = state;

  const fetchCallHistory = async () => {
    const obj = {
      pageIndex: currentPage,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      searchText: searchText,
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
      projectId: selectedProjectId ? [selectedProjectId] : "",
      callStatus: statusFilter,
      outcome: outComefilter,
      timezone,
    };

    await dispatch(getCallHistoryAction(obj));
    setLoading(false);
    await dispatch(getCallHistoryCountAction(obj));
  };

  const applyRefresh = () => {
    setLoading(true);
    fetchCallHistory();
  };

  const openCallTranscriptModal = (transcriptData) => {
    setSelectedTranscript(transcriptData);
    setShowCallTranscriptModal(true);
  };

  const closeCallTranscriptModal = () => {
    setShowCallTranscriptModal(false);
    setSelectedTranscript(null);
  };

  const handleRedirect = (callData) => {
    if (callData?.isDeleted) {
      swalWarningMessage("Project is Deleted.");
      return;
    } else if (callData?.campaignDeleted) {
      swalWarningMessage("Campaign is deleted.");
      return;
    } else {
      history.push(
        `/account/project/${callData?.projectId}/campaign-runs?campaignid=${callData?.campaignId}&campdate=${callData?.createdAt}`
      );
    }
  };

  const exportToCSV = async () => {
    setExportLoad(true);
    let obj = {
      pageIndex: currentPage,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      searchText: searchText,
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
      projectId: selectedProjectId ? [selectedProjectId] : "",
      callStatus: statusFilter,
      outcome: outComefilter,
      timezone,
    };
    let res: any = await dispatch(getCallHistoryAction({ ...obj, all: true }));
    if (res?.length <= 0) {
      swalErrorMessage("Nothing to export.");
      setExportLoad(false);
      return;
    }
    let matchArr = [
      "mandatory_params",
      "firstName",
      "lastName",
      "FirstName",
      "LastName",
      "phone",
      "PhoneNum",
    ];

    if (res) {
      let arr: any = [];
      res?.forEach((kl) => {
        let callHistory = kl?.combinedData?.campaignRunDetails;
        let copy = callHistory?.customParameters;
        let obj: any = {};
        obj["First Name"] = copy?.FirstName || "--";
        obj["Last Name"] = copy?.LastName || "--";
        obj["Phone number"] = callHistory?.phone || "--";
        obj["Call UUID"] = callHistory?.call_uuid || "";
        obj["Calling Status"] =
          constantData?.callStatus[callHistory?.callStatus] || "";
        obj["Cost"] = callHistory?.newCost || "";
        if (selectedAccount?.params?.pricesWaivers) {
          obj["Cost"] = "--";
        }
        obj["Recording Url"] = callHistory?.callRecordingUrl || "";
        if (!isEmpty(copy)) {
          for (const customKeys in copy) {
            if (!matchArr.includes(customKeys)) {
              obj[customKeys] = copy[customKeys];
            }
          }
        }
        arr.push(obj);
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(arr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Call History Report` + fileExtension);
    }
    await dispatch(getCallHistoryAction({ ...obj, timezone }));
    setExportPopup(true);
    setExportLoad(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const toggle = () => {
    setState((prev) => ({
      ...prev,
      filterDrawer: !prev.filterDrawer,
    }));
  };

  const exportToggle = () => {
    setExportPopup((prev) => !prev);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getName = (phone) => {
    const firstName = phone?.customParameters?.firstName || "";
    const lastName = phone?.customParameters?.lastName || "";
    return `${firstName} ${lastName}`.trim() || "No Name";
  };

  const obj = {
    searchText,
    setMainState: setState,
    trackEnabledRecording,
    mainState: state,
    startDate,
    endDate,
    currentDate,
    setDateRange,
    filterName,
    setFilterName,
    setFilterNameFromDropdown,
    setSearchTerm,
  };

  return (
    <Box sx={{ padding: 1, position: "relative" }}>
      <CallHistoryHeader
        setIsReady={setIsReady}
        setSearchText={setSearchText}
        exportToCSV={exportToCSV}
        toggleFilterDrawer={toggle}
        totalCalls={callHistoryDetailsTotalCounts}
        exportLoad={exportLoad}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        applyRefresh={applyRefresh}
        loading={loading}
      />
      <DynamicTable
        columns={[
          {
            label: "Phone Number",
            render: (row) => (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {getName(row?.campaignRunDetails)}
                  </Tooltip>
                }
                rootClose={true}
                rootCloseEvent={"click"}
                onExit={() => {}}
              >
                <span className="d-inline-block">
                  <span style={{ pointerEvents: "none" }}>
                    {shortenString(row?.campaignRunDetails?.phone, 15)}{" "}
                  </span>
                </span>
              </OverlayTrigger>
            ),
          },
          {
            label: "Call Status",
            render: (row) => {
              const status =
                constantData["callStatus"][row?.campaignRunDetails?.callStatus];
              let statusColor =
                constantData["callStatusColour"][
                  row?.campaignRunDetails?.callStatus
                ] || "#333333";

              try {
                statusColor = chroma(statusColor).hex();
              } catch (error) {
                statusColor = "#333333";
              }
              const backgroundColor = chroma(statusColor).alpha(0.1).hex();

              return (
                <StatusButton
                  status={status}
                  statusColor={statusColor}
                  backgroundColor={backgroundColor}
                />
              );
            },
          },
          {
            label: "Cost",
            render: ({ campaignRunDetails }) =>
              selectedAccount?.params?.pricesWaivers
                ? "--"
                : campaignRunDetails?.newCost || "--",
          },
          {
            label: "UUID",
            render: (row) => row?.campaignRunDetails?.call_uuid,
            styles: { textTransform: "none", padding: 2 },
          },
          {
            label: "Recording",
            render: (row) => (
              <Box className="flex-xy-center">
                <ReactAudioPlayer
                  src={
                    row?.campaignRunDetails?.callRecordingUrl ||
                    `https://storage.googleapis.com/botstream-recordings/${row?.campaignRunDetails?.call_uuid}.mp3`
                  }
                  controls
                  style={{
                    minWidth: "40px",
                    height: "30px",
                  }}
                />
              </Box>
            ),
          },
          {
            label: "Actions",
            render: (row) => (
              <>
                <BaseButton
                  disabled={
                    !["703", "712"].includes(
                      row?.campaignRunDetails?.callStatus
                    )
                  }
                  title="View Transcript"
                  handleClick={() =>
                    openCallTranscriptModal(row?.campaignRunDetails)
                  }
                  styles={{ color: "#4280FF" }}
                />

                <CallHistoryPopover callData={row} />

                <IconButton
                  title="Redirect"
                  onClick={() => handleRedirect(row)}
                >
                  <RedirectIcon />
                </IconButton>
                {selectedAccount?.params?.logAccess?.includes(Stalker) && (
                  <button
                    className="custom-button-icon"
                    title="Logs"
                    onClick={() => {
                      setCall_uuid(row?.campaignRunDetails?.call_uuid);
                      setShow(true);
                    }}
                  >
                    Logs
                  </button>
                )}
              </>
            ),
          },
        ]}
        data={campaignDataList}
        loading={loading}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        noDataAvailableMessage="No Call History Available"
        totalRowCount={callHistoryDetailsTotalCounts}
        rowCount={campaignDataList?.length}
      />

      {showCallTranscriptModal && (
        <CallTranscriptModal
          show={showCallTranscriptModal}
          onClose={closeCallTranscriptModal}
          selectedTranscript={selectedTranscript}
        />
      )}

      {filterDrawer && (
        <Drawer anchor={"right"} open={filterDrawer} onClose={toggle}>
          {<FilterBody obj={obj} />}
        </Drawer>
      )}
      {call_uuid && (
        <CallLogs
          call_uuid={call_uuid}
          show={show}
          toggle={() => {
            setCall_uuid("");
            setShow(false);
          }}
        />
      )}
      {exportPopup && (
        <CommonPopup
          message={`The selected data are exported successfully`}
          show={exportPopup}
          toggle={exportToggle}
          Icon={ExportFileIcon}
          heading="File exported successfully"
        />
      )}
    </Box>
  );
};

export default CallHistory;
