import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getAllCampaignDetailsAction,
  getCampaignDetailsAllAction,
  getCampaignEntityAction,
  getCampaignEntityListAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DynamicTable from "../../CommonComponents/Table/DynamicTable";
import ReactAudioPlayer from "react-audio-player";
import * as FileSaver from "file-saver";
import BaseButton from "../../Common/Buttons/BaseButton";
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import { isEmpty } from "../../CommonComponents/Common";
import * as XLSX from "xlsx";
import { getDecryptedItem } from "../../../../utils/localStorageHelper";

const Insight = ({ campaignName, all = false }) => {
  const dispatch = useDispatch();
  const campaignData: any = getDecryptedItem("campaign");
  const { projectId } = useParams<{ projectId: string }>();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  const campaignRuns = locSearch.get("campaignRuns");
  const [allCampaignRunDetails, setAllCampaignRunDetails] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<any>({
    questions: [{ question: "", key: "", type: "" }],
    about: "",
    data: [],
    selectedData: [],
    isDisabled: false,
  });

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const campaignsObj = {
        pageIndex: 1,
        pageSize: 1000,
        live: false,
        callStatus: ["703", "712"],
      };
      let campaignsData = [];
      if (all) {
        const params = { page: 0, page_size: 0 };
        const res = await getCampaignEntityListAction(
          campaignData?._id,
          params
        );
        campaignsData = res?.data;

        const campaigns = await dispatch(
          getAllCampaignDetailsAction(campaignData?._id, {
            ...campaignsObj,
            isDocsOnly: "true",
          })
        );

        setAllCampaignRunDetails(campaigns);
      } else {
        const params = {
          campaign_name: projectId,
          campaign_run_id: campaignRuns,
        };
        campaignsData = await getCampaignEntityAction(params);

        const res: any = await dispatch(
          getCampaignDetailsAllAction(projectId, campaignRuns, campaignsObj)
        );
        setAllCampaignRunDetails(res);
      }
      setState((prev) => ({
        ...prev,
        data: campaignsData,
      }));
      setLoading(false);
    };
    init();
    return () => {
      setState((prev) => ({
        ...prev,
        data: [],
      }));
    };
  }, []);
  const getName = (phone) => {
    const nameFind1 = allCampaignRunDetails?.find((num) => num.phone === phone);
    return {
      firstName: nameFind1?.customParameters?.firstName || "--",
      lastName: nameFind1?.customParameters?.lastName || "--",
      recordingUrl: nameFind1?.callRecordingUrl || "--",
    };
  };
  const getData = (output) => {
    if (typeof output === "string") return output;
    const outputData: Array<string> = [];
    if (Array.isArray(output)) {
      output?.map((data) => outputData.push(`${data?.key} : ${data?.value}`));
    } else {
      return `${output?.key} : ${output?.value}`;
    }
    return outputData.join(",");
  };
  const ExportCSV = async () => {
    let selectedCopy: any = state.data;
    selectedCopy = selectedCopy.map((da, idx) => {
      return {
        recording_url: da.recording_url,
        phone_number: da.phone_number,
        output: da.output,
        idx: idx,
      };
    });
    setState((prev) => ({
      ...prev,
      selectedData: selectedCopy,
    }));
    if (selectedCopy.length > 0) {
      let modifiedArr: Array<object> = [];
      selectedCopy.forEach((data: any) => {
        let nameFind1 = allCampaignRunDetails?.find(
          (num) => num?.phone === data?.phone_number
        );
        let obj = {
          "First Name": nameFind1?.customParameters?.firstName || "--",
          "Last Name": nameFind1?.customParameters?.lastName || "--",
          "Recording Url": nameFind1?.callRecordingUrl,
          "Phone Number": data.phone_number,
          // Output: getData(data?.output),
        };
        if (!isEmpty(data?.output)) {
          data?.output.forEach((out) => {
            obj[out.key] = out.value;
          });
        }
        modifiedArr.push(obj);
      });
      setState((prev) => ({
        ...prev,
        exportSpin: true,
      }));
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(modifiedArr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Campaign Report ${campaignName}` + fileExtension);
    }
    setState((prev) => ({
      ...prev,
      exportSpin: false,
    }));
  };
  return (
    <Box>
      {state?.data?.length > 0 && (
        <Box display={"flex"} justifyContent={"flex-end"}>
          <BaseButton
            title="Export"
            variant="outlined"
            startIcon={<ExportIcon height={14} width={14} />}
            loading={false}
            handleClick={ExportCSV}
            styles={{ px: 2, fontSize: 12 }}
          />
        </Box>
      )}
      <Box mt={2}>
        <DynamicTable
          columns={[
            {
              label: "First Name",
              render: (data) => {
                const nameData = getName(data?.phone_number);
                return nameData?.firstName;
              },
            },
            {
              label: "Last Name",
              render: (data) => {
                const nameData = getName(data?.phone_number);
                return nameData?.lastName;
              },
            },
            {
              label: "Phone Number",
              field: "phone_number",
            },
            {
              label: "Recording",
              render: (data) => {
                return (
                  <ReactAudioPlayer
                    src={`https://storage.googleapis.com/botstream-recordings/${data?.call_uuid}.mp3`}
                    controls
                    style={{
                      minWidth: "40px",
                      height: "30px",
                    }}
                  />
                );
              },
            },
            {
              label: "Output",
              render: (data) => getData(data?.output),
            },
          ]}
          data={state?.data}
          loading={loading}
          totalPages={1}
          currentPage={1}
          handleNext={() => {}}
          noDataAvailableMessage="No Insights Available"
          rowCount={state?.data?.length}
        />
      </Box>
    </Box>
  );
};
export default Insight;
