import React, { useEffect, useState } from "react";
import { Box, CssBaseline, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";
import BaseButton from "../Common/Buttons/BaseButton";
import { LoadingSlides, PAGE_SIZE_50 } from "../CommonComponents/Common";
import {
  AddLeadsAction,
  getLeadsDataAction,
} from "../Projects/Store/projects.action";
import Dashboard from "./Dashboard";

const DashboardHome = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const leads = {
    projectId,
    data: [
      {
        firstName: "Divyansh",
        lastName: "Sharma",
        phoneNum: "917051373005",
        dateOfSurgery: "2025-03-25",
        testResult: "Pending",
        insurance: "UnitedHealthcare #912",
      },
      {
        firstName: "Deabsmita",
        lastName: "Das",
        phoneNum: "918777253565",
      },
      {
        firstName: "Divyansh",
        lastName: "Gupta",
        phoneNum: "917051373005",
        dateOfSurgery: "2025-03-04",
        testResult: "Pending",
        insurance: "UnitedHealthcare #911",
      },
    ],
  };

  const fetchLeadsData = async () => {
    setLoading(true);
    const params = {
      pageIndex,
      pageSize: PAGE_SIZE_50,
      searchtext: "",
    };
    await dispatch(getLeadsDataAction(params));
    setLoading(false);
  };
  useEffect(() => {
    fetchLeadsData();
  }, [pageIndex]);

  const handleLeadUpload = async () => {
    await AddLeadsAction(leads);
  };

  return (
    <>
      <CssBaseline />
      <Box display="flex" justifyContent="flex-end" gap={1}>
        <IconButton
          title="Refresh"
          onClick={fetchLeadsData}
          sx={styles.refreshButtonStyles}
          disabled={loading}
        >
          <RefreshIcon className="refresh-icon" height={14} width={14} />
        </IconButton>
        <BaseButton
          variant="contained"
          title="Add Leads"
          handleClick={handleLeadUpload}
        />
      </Box>
      {loading ? (
        <Box sx={styles.loadingContainer}>
          <LoadingSlides bgColor="#FF5E00" />
        </Box>
      ) : (
        <Dashboard pageIndex={pageIndex} setPageIndex={setPageIndex} />
      )}
    </>
  );
};
export default DashboardHome;

const styles = {
  loadingContainer: {
    position: "absolute",
    top: 0,
    width: "75%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
  refreshButtonStyles: {
    borderRadius: 2,
    px: 1.25,
    color: "#D1D9EF",
    border: 1,
    "&:hover": {
      borderColor: "#666",
    },
  },
};
