import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import { Box, Button, Pagination, Stack, Typography } from "@mui/material";
import "../../../assets/css/page/ResizableGrid.scss";
import styles from "../../../assets/MUIStyles/dynamicTableStyles";
import { PAGE_SIZE_50 } from "./Common";

const ResizableGrid = ({ columns, allRows, pageIndex, setPageIndex }) => {
  const handlePageChange = (_, newPage) => setPageIndex(newPage);

  return (
    <>
      <Box
        className="custom-grid-container ag-theme-material"
        sx={{ height: 500, width: "100%" }}
      >
        <AgGridReact
          columnDefs={columns}
          rowData={allRows}
          defaultColDef={{
            resizable: true,
            minWidth: 100,
          }}
          suppressColumnVirtualisation={true}
          rowHeight={120}
          getRowStyle={(params) => {
            if (params?.data?.doNotContact) {
              return { backgroundColor: "#F8D7DA" };
            }
            return {};
          }}
        />
      </Box>

      <Stack direction="row" sx={styles.bottomContainer}>
        <Box color="#555555" display="flex" gap={1.5} alignItems="center">
          <Typography fontSize={12} fontWeight={500}>
            Total
          </Typography>
          <Typography
            component="span"
            fontSize={12}
            fontWeight={500}
            border={1}
            borderColor="#CCD2DE"
            px={1.5}
            py={0.25}
            borderRadius={2}
          >
            {`${allRows.length} row(s)`}
          </Typography>
        </Box>

        <>
          {/* <Pagination
            count={totalPages}
            page={pageIndex}
            onChange={handlePageChange}
            color="primary"
            shape="rounded"
            size="medium"
            variant="outlined"
            siblingCount={0}
            boundaryCount={1}
            sx={styles.pagination}
          /> */}
          <Box display="flex" gap={3}>
            <Button
              variant="outlined"
              onClick={() => setPageIndex((prev) => Math.max(prev - 1, 1))}
              disabled={pageIndex === 1}
              sx={styles.paginationButtons}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                setPageIndex((prev) => Math.min(prev + 1, allRows?.length))
              }
              disabled={allRows?.length < PAGE_SIZE_50}
              sx={styles.paginationButtons}
            >
              Next
            </Button>
          </Box>
        </>
      </Stack>
    </>
  );
};

export default ResizableGrid;
