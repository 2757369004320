import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as PauseIcon } from "../../../../assets/Images/PauseIcon.svg";
import { ReactComponent as DotIcon } from "../../../../assets/Images/Text.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/Images/SuccessProjectIcon.svg";
import {
  swalConfirmationMessage,
  swalErrorMessage,
} from "../../../../Constant/swalMessage";
import { updateCurrentPath } from "../../../../store/actions/root.actions";
import useQueryParams from "../../../Hooks/useQueryParams";
import {
  getCustomWriteAccess,
  PAGE_SIZE_50,
} from "../../CommonComponents/Common";
import DynamicTable from "../../CommonComponents/Table/DynamicTable";
import {
  getCampaignByIdAction,
  getCampaignRunsAction,
  getCampaignRunsCountAction,
  stopCampaignRunAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import CampaignRunsHeader from "./CampaignRunsHeader";
import StatusButton from "../../Common/Buttons/StatusButton";
import { shortenString } from "../../../../Constant/helper";
import BaseButton from "../../Common/Buttons/BaseButton";
import SummaryCard from "../Campaign/SummaryCards";
import { Box } from "@mui/material";
import moment from "moment";
import moment1 from "moment-timezone";
import CommonPopup from "../../CommonComponents/PopUpCards/CommonPopup";
import {
  getDecryptedItem,
  setEncryptedItem,
} from "../../../../utils/localStorageHelper";

const convertTime = (time) => {
  const localTime = moment.utc(time).tz(moment1.tz.guess());
  return (
    <Box className="flex-xy-center" gap={0.5}>
      {localTime.format("YYYY-MM-DD")}
      <DotIcon />
      {localTime.format("hh:mm a")}
    </Box>
  );
};

function CampaignRunList(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const highlightedRowRef = useRef<HTMLTableRowElement | null>(null);
  const reducer: any = useSelector((state) => state);

  const {
    CampaignReducer: { campaignsRuns, campaignsRunsTotalCount },
    AccountReducer: { selectedAccount },
  } = reducer;

  const [spin, setSpin] = useState(false);
  const { projectId } = useParams<{ projectId: string }>();
  const { campaignid, campdate } = useQueryParams();
  const [data, setData] = useState<any>({});
  const [state, setState] = useState({
    campaignRunList: [],
    totalCallsConnected: 0,
    totalCallsMade: 0,
    pageIndex: Number(getDecryptedItem("campaignRunpageIndex")) || 1,
  });
  const { campaignRunList, pageIndex } = state;
  const [allColumns, setAllColumns] = useState([]);
  const [successPopup, setSuccessPopup] = useState(false);

  useEffect(() => {
    setSpin(true);

    let callFn = async () => {
      const obj = {
        pageIndex: 1,
        pageSize: PAGE_SIZE_50,
      };
      let res: any = await dispatch(
        getCampaignRunsAction(projectId, campaignid, obj)
      );
      if (res?.status === 400) {
        swalErrorMessage(res?.errMsg);
        history.push(`/account/project/${projectId}/campaigns`);
        return;
      }
      await dispatch(getCampaignRunsCountAction(projectId, campaignid));
      setSpin(false);
    };
    callFn();
  }, [dispatch]);

  useEffect(() => {
    if (campaignsRuns) {
      let totalCalls = 0;
      let totalConn = 0;
      for (const camp of campaignsRuns) {
        totalCalls = totalCalls + Number(camp?.audienceCount || 0);
        totalConn = totalConn + Number(camp?.callEnded || 0);
      }
      setState((prev) => ({
        ...prev,
        campaignRunList: campaignsRuns,
        totalCallsConnected: totalConn,
        totalCallsMade: totalCalls,
      }));
    }
  }, [campaignsRuns]);

  useEffect(() => {
    if (highlightedRowRef.current) {
      highlightedRowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [campaignRunList, campdate]);

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = async () => {
    const obj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      campaignId: campaignid,
    };
    const res: any = await dispatch(getCampaignByIdAction(projectId, obj));
    setData(res);
    dispatch(updateCurrentPath("Campaign Runs", ""));

    const columns: any = [
      {
        label: "STARTED AT",
        render: (campaignRun) => convertTime(campaignRun?.createdAt),
        styles: { textTransform: "none", textWrap: "nowrap" },
      },
      ...(res?.range
        ? [
            {
              label: "RANGE",
              field: "range",
            },
          ]
        : []),

      {
        label: "STATUS",
        render: (campaignRun) => {
          const { status, statusColor, backgroundColor } =
            getStatus(campaignRun);

          return (
            <StatusButton
              status={shortenString(status, 20)}
              statusColor={statusColor}
              backgroundColor={backgroundColor}
            />
          );
        },
      },
      {
        label: "AUDIENCE COUNT",
        render: ({ audienceCount }) => audienceCount || 0,
      },
      {
        label: "COST",
        render: ({ campaignRunCost }) =>
          selectedAccount?.params?.pricesWaivers ||
          !campaignRunCost?.[0]?.campaignRunCost
            ? "--"
            : `${selectedAccount?.currency === "inr" ? "₹" : "$"} ${Number(
                campaignRunCost?.[0]?.campaignRunCost
              ).toFixed(3)}`,
      },
      {
        label: "DETAILS",
        render: (campaignRun) => (
          <BaseButton
            title="View Details"
            handleClick={() => viewCampaignRunDetails(campaignRun)}
            styles={{ color: "34280FF" }}
          />
        ),
      },
      ...(getCustomWriteAccess(reducer) === "write"
        ? [
            {
              label: "ACTIONS",
              render: (campaignRun) => (
                <BaseButton
                  title={campaignRun?.suspended ? "Stopped" : "Stop Campaign"}
                  handleClick={() => suspendCalls(campaignRun)}
                  disabled={
                    (campaignRun?.completed || campaignRun?.suspended
                      ? true
                      : false) ||
                    (campaignRun?.errMsg && true)
                  }
                  startIcon={<PauseIcon />}
                />
              ),
            },
          ]
        : []),
    ];
    setAllColumns(columns);
  };

  const viewCampaignRunDetails = async (campaignRun) => {
    let obj = {
      ...campaignRun,
      type: false,
    };
    setEncryptedItem("campaignRuns", obj);
    history.push(
      `/account/project/${projectId}/campaign-runs-details?campaignid=${campaignid}&campaignRuns=${campaignRun?._id}&live=false`
    );
  };

  const suspendCallList = async (camp) => {
    let obj = {
      listId: camp?.listId,
      campaignRunId: camp?._id,
      serviceProvider: camp?.serviceProvider,
    };
    let res: any = await dispatch(stopCampaignRunAction(obj));
    const obj1 = {
      pageIndex: pageIndex,
      pageSize: PAGE_SIZE_50,
    };
    await dispatch(getCampaignRunsAction(projectId, campaignid, obj1));
    if (res) {
      setSuccessPopup(true);
    }
  };

  const suspendCalls = (camp) => {
    swalConfirmationMessage(
      "Do you want to terminate the campaign?",
      "Terminate!",
      () => {},
      async () => await suspendCallList(camp)
    );
  };

  const toggleSuccessPopup = () => {
    setSuccessPopup((prev) => !prev);
  };

  const getStatus = (campaign) => {
    if (campaign?.errMsg) {
      return {
        status: campaign?.errMsg,
        statusColor: "#D0B656",
        backgroundColor: "#F5F0E4",
      };
    }
    if (campaign?.completed) {
      return {
        status: "completed",
        statusColor: "#5AA552",
        backgroundColor: "#E3F3EB",
      };
    } else if (campaign?.suspended) {
      return {
        status: "stopped",
        statusColor: "#D84B28",
        backgroundColor: "#FCF0ED",
      };
    } else
      return {
        status: "in-progress",
        statusColor: "#67A8AE",
        backgroundColor: "#E4F2F5",
      };
  };

  const handlePageNavigation = async (page) => {
    const obj = {
      pageIndex: page,
      pageSize: PAGE_SIZE_50,
    };
    await dispatch(getCampaignRunsAction(projectId, data?._id, obj));
    setState((prev) => ({
      ...prev,
      pageIndex: page,
    }));
    setEncryptedItem("campaignRunpageIndex", page);
  };

  return (
    <>
      <CampaignRunsHeader
        handleBack={() => {
          history.push(`/account/project/${projectId}/campaigns`);
        }}
        handleViewAllRuns={() => {
          history.push(
            `/account/project/${projectId}/all-runs-details?campaign-id=${data?._id}`
          );
        }}
        campaign={data}
      />
      <Box
        py={3}
        display="grid"
        gridTemplateColumns="repeat(7, 1fr)"
        gap={2}
        sx={{
          "@media (max-width: 1300px)": {
            gridTemplateColumns: "repeat(6, 1fr)",
          },
          "@media (max-width: 1200px)": {
            gridTemplateColumns: "repeat(5, 1fr)",
          },
          "@media (max-width: 1020px)": {
            gridTemplateColumns: "repeat(4, 1fr)",
          },
        }}
      >
        <SummaryCard
          title="Campaign Runs"
          value={campaignsRunsTotalCount?.totalCampaignRun || 0}
          circleColor="linear-gradient(180deg, rgba(255, 150, 143, 0.8) 0%, rgba(226, 76, 66, 0.8) 100%)"
        />
        <SummaryCard
          title="Calls Connected"
          value={campaignsRunsTotalCount?.CallsConnected || 0}
          circleColor="linear-gradient(237.96deg, rgba(150, 255, 143, 0.8) -12.91%, rgba(87, 209, 78, 0.8) 100.74%)"
        />
        <SummaryCard
          title="Calls Made"
          value={`${
            Number(campaignsRunsTotalCount?.CallFailed || 0) +
            Number(campaignsRunsTotalCount?.CallsConnected || 0)
          }`}
          circleColor="linear-gradient(122.19deg, rgba(255, 143, 224, 0.8) 34.92%, rgba(242, 93, 203, 0.8) 100.02%)"
        />
      </Box>
      <DynamicTable
        columns={allColumns}
        data={campaignRunList}
        showTotalRows={true}
        loading={spin}
        totalPages={Math.ceil(
          Number(campaignsRunsTotalCount?.totalCampaignRun) / PAGE_SIZE_50
        )}
        currentPage={Number(pageIndex)}
        handlePageChange={(event, newPage) => handlePageNavigation(newPage)}
        handlePrevious={() => handlePageNavigation(pageIndex - 1)}
        handleNext={() => handlePageNavigation(pageIndex + 1)}
        noDataAvailableMessage={"No Campaign Runs Available"}
        rowCount={campaignsRunsTotalCount?.totalCampaignRun}
        highlightRowCondition={(row) => row?.createdAt === campdate}
      />
      {successPopup && (
        <CommonPopup
          message={`Campaign stopped successfully`}
          show={successPopup}
          toggle={toggleSuccessPopup}
          Icon={SuccessIcon}
          heading="Campaign Stopped"
        />
      )}
    </>
  );
}

export default CampaignRunList;
