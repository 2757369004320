import React from "react";
import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";

const EditLeadModal = ({ formData, handleChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.split(",").map((item) => item.trim());
    handleChange({ target: { name, value: JSON.stringify(formattedValue) } });
  };
  return (
    <Box px={2}>
      <Box display="flex" gap={2.5}>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">First Name</Typography>
          <TextField
            name="firstName"
            value={formData?.firstName || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your first name"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 25 }}
          />
        </Box>

        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">Last Name</Typography>
          <TextField
            name="lastName"
            value={formData?.lastName || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your last name"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 25 }}
          />
        </Box>
      </Box>
      <Box display="flex" gap={2.5}>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">Email</Typography>
          <TextField
            name="email"
            value={formData?.email || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your email"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 25 }}
          />
        </Box>

        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">Phone Number</Typography>
          <TextField
            name="phoneNum"
            value={formData?.phoneNum || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your phone number"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 25 }}
          />
        </Box>
      </Box>
      <Box display="flex" gap={2.5}>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">Date Of Birth</Typography>
          <TextField
            type="date"
            name="dateOfBirth"
            value={formData?.dateOfBirth || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            sx={commonStyles.inputField}
          />
        </Box>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">Date Of Surgery</Typography>
          <TextField
            type="date"
            name="dateOfSurgery"
            value={formData?.dateOfSurgery || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            sx={commonStyles.inputField}
          />
        </Box>
      </Box>
      <Box display="flex" gap={2.5}>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">Name Of Surgeon</Typography>
          <TextField
            type="text"
            name="nameOfSurgeon"
            value={formData?.nameOfSurgeon || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            sx={commonStyles.inputField}
            placeholder="Enter surgeon name"
          />
        </Box>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">
            Phone Of Surgeon
          </Typography>
          <TextField
            name="phoneOfSurgeon"
            value={formData?.phoneOfSurgeon || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter surgeon phone number"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 25 }}
          />
        </Box>
      </Box>
      <Box display="flex" gap={2.5}>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">
            Email Of Surgeon
          </Typography>
          <TextField
            name="emailOfSurgeon"
            value={formData?.emailOfSurgeon || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter surgeon email"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 25 }}
          />
        </Box>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">Insurance</Typography>
          <TextField
            name="insurance"
            value={formData?.insurance || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your insurance"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 25 }}
          />
        </Box>
      </Box>
      <Box display="flex" gap={2.5}>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">
            Pre-existing Condition
          </Typography>
          <TextField
            name="preExistingCondition"
            value={formData?.preExistingCondition || ""}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your pre-existing condition"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 25 }}
          />
        </Box>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">
            Test Results Received
          </Typography>
          <TextField
            name="testsDone"
            value={
              Array.isArray(formData?.testsDone)
                ? formData.testsDone.join(", ")
                : formData?.testsDone
                ? JSON.parse(formData.testsDone)?.join(", ")
                : ""
            }
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            placeholder="Enter test results received"
            sx={commonStyles.inputField}
          />
        </Box>
      </Box>
      <Box display="flex" gap={2.5}>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">Tests Not Done</Typography>
          <TextField
            name="testsNotDone"
            value={JSON.parse(formData?.testsNotDone || "[]")?.join(", ") || ""}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your tests not done"
            sx={commonStyles.inputField}
          />
        </Box>
        <Box width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">
            All Tests Needed
          </Typography>
          <TextField
            name="actualTests"
            value={
              Array.isArray(formData?.actualTests)
                ? formData.actualTests.join(", ")
                : formData?.actualTests
                ? JSON.parse(formData.actualTests)?.join(", ")
                : ""
            }
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            placeholder="Enter all tests needed"
            sx={commonStyles.inputField}
          />
        </Box>
      </Box>
      <Box display="flex" gap={2.5}>
        <Box className="flex-column" width={1 / 2} mb={1}>
          <Typography className="font-14-gery mb-1">Do Not Call</Typography>
          <Select
            sx={{ height: "40px", borderRadius: "8px" }}
            name="doNotContact"
            value={String(formData?.doNotContact)}
            onChange={handleChange}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default EditLeadModal;
