import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { ReactComponent as EditIcon } from "../../../assets/Images/EditIcon.svg";
import { ReactComponent as ExportFileIcon } from "../../../assets/Images/ExportPopupIcon.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/Images/SuccessProjectIcon.svg";
import { swalErrorMessage } from "../../../Constant/swalMessage";
import BaseButton from "../Common/Buttons/BaseButton";
import { PAGE_SIZE_50 } from "../CommonComponents/Common";
import CommonModal from "../CommonComponents/Modal/CommonModal";
import CommonPopup from "../CommonComponents/PopUpCards/CommonPopup";
import {
  exportLeadsDataAction,
  getLeadsDataAction,
  updateLeadsAction,
} from "../Projects/Store/projects.action";
import EditLeadModal from "./EditLeadModal";
import PatientTableFilters from "./PatientTableFilters";
import PatientTableHeader from "./PatientTableHeader";
import ResizableGrid from "../CommonComponents/ResizableGrid";
import { ColDef } from "ag-grid-community";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";
import {ReactComponent as DownloadIcon} from "../../../assets/icons/download.svg";
import MedicalReport from "../MedicalReciet/MedicalReciept";

const columnOrder = [
  { key: "_id", label: "Patient Id" },
  { key: "firstName", label: "First Name" },
  { key: "lastName", label: "Last Name" },
  { key: "email", label: "Email" },
  { key: "phoneNum", label: "Phone Number" },
  { key: "dateOfBirth", label: "Date of Birth" },
  { key: "insurance", label: "Insurance" },
  { key: "preExistingCondition", label: "Pre-existing Condition" },
  { key: "dateOfSurgery", label: "Date of Surgery" },
  { key: "pdfRec", label: "Record Type" },
  { key: "testsDone", label: "Test Results Received" },
  { key: "testsNotDone", label: "Tests Not Done" },
  { key: "actualTests", label: "All Tests Needed" },
  { key: "nameOfSurgeon", label: "Name Of Surgeon" },
  { key: "insights", label: "Insights" },
];

const PatientTable = ({ pageIndex, setPageIndex }) => {
  const dispatch = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const {
    ProjectReducer: { leadsList: data },
  } = reducer;

  const [exportPopup, setExportPopup] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [patientPopup, setPatientPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredData, setFilteredData] = useState(data || []);
  const [viewRecord, setViewRecord] = useState([]);
  const [editRecordModal, setEditRecordModal] = useState(false);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNum: "",
    dateOfBirth: "",
    insurance: "",
    preExistingCondition: "",
    dateOfSurgery: "",
    testsDone: "",
    testsNotDone: "",
    actualTests: [],
    nameOfSurgeon: "",
    emailOfSurgeon: "",
    phoneOfSurgeon: "",
    doNotContact: false,
  }) as any;

  const popoverOpen = Boolean(anchorEl);

  useEffect(() => {
    setFilteredData(data || []);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;
    if (name === "doNotContact") {
      updatedValue = value === "true"
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  const handleSave = async () => {
    setEditButtonLoading(true);
    const data = {
      data: formData,
    };
    await updateLeadsAction(formData?._id, data);
    const params = {
      pageIndex,
      pageSize: PAGE_SIZE_50,
      searchtext: "",
    };
    await dispatch(getLeadsDataAction(params));
    setPatientPopup(true);
    setEditRecordModal(false);
    setEditButtonLoading(false);
  };

  const handleEdit = (lead) => {
    setEditRecordModal(true);
    setFormData(lead);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const exportToCSV = async () => {
    setExportLoading(true);
    const params = {
      pageIndex: 1,
      pageSize: 1000,
      searchtext: "",
    };
    const res: any = await dispatch(exportLeadsDataAction(params));

    if (!res || res?.length === 0) {
      swalErrorMessage("Nothing to export.");
      setExportLoading(false);
      return;
    }
    const testKeys = new Set(["testsDone", "testsNotDone", "actualTests"]);
    const dateKeys = new Set(["dateOfBirth", "dateOfSurgery"]);
    const insightsKeys = [
      ...new Set(
        res?.flatMap((item) => item?.insights?.map((i) => i.key) || [])
      ),
    ];
    const arr: any = res?.map((lead) => {
      const obj: any = {};

      columnOrder.forEach(({ key, label }) => {
        if (dateKeys.has(key)) {
          obj[label] = lead[key]
            ? moment(lead[key]).format("DD-MM-YYYY")
            : "--";
        } else if (testKeys.has(key)) {
          const data = Array.isArray(lead[key])
            ? lead[key].join(", ")
            : lead[key]
            ? JSON.parse(lead[key])?.join(", ")
            : "";
          obj[label] = data;
        } else if (["pdfRec", "insights"]?.includes(key)) {
          return;
        } else {
          obj[label] = lead[key] || "--";
        }
      });

      insightsKeys?.forEach((key: any) => {
        obj[
          key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
        ] = lead?.insights?.find((item) => item?.key === key)?.value || "--";
      });

      // obj["Documents"] = Array.isArray(lead?.pdf) ? lead.pdf.join(", \r\n") : "";

      return obj;
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Patients` + fileExtension);

    setExportPopup(true);
    setExportLoading(false);
  };

  const filtersObj = {
    popoverOpen,
    handlePopoverClose,
    anchorEl,
    columnOrder,
    setFilteredData,
  };

  const handleDownloadPDF = async (patient) => {
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.left = "-9999px";
    document.body.appendChild(container);

    ReactDOM.render(<MedicalReport patientData={patient} />, container);

    const canvas = await html2canvas(container, {
      scale: 1.5,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.7);

    const imgWidth = canvas.width * 0.75;
    const imgHeight = canvas.height * 0.75;

    const pdf = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: [imgWidth, imgHeight],
    });

    pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
    pdf.save("Medical_Receipt.pdf");

    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  };

  const columnsDef: ColDef[] = [
    {
      headerName: "Patient Id",
      field: "_id",
      pinned: "left",
      width: 150,
      resizable: true,
      valueGetter: (params) => params?.data?._id || "--",
    },
    {
      headerName: "First Name",
      field: "firstName",
      pinned: "left",
      width: 150,
      resizable: true,
      valueGetter: (params) => params?.data?.firstName || "--",
    },
    {
      headerName: "Last Name",
      field: "lastName",
      pinned: "left",
      width: 150,
      resizable: true,
      valueGetter: (params) => params?.data?.lastName || "--",
    },
    {
      headerName: "Email",
      field: "email",
      width: 200,
      resizable: true,
      valueGetter: (params) => params?.data?.email || "--",
    },
    {
      headerName: "Phone Number",
      field: "phoneNum",
      width: 150,
      resizable: true,
      valueGetter: (params) => params?.data?.phoneNum || "--",
    },
    {
      headerName: "Date of Birth",
      field: "dateOfBirth",
      width: 150,
      resizable: true,
      cellRendererFramework: (params) => {
        return moment(params?.data?.dateOfBirth).format("DD-MM-YYYY") || "--";
      },
    },
    {
      headerName: "Insurance",
      field: "insurance",
      width: 150,
      resizable: true,
      valueGetter: (params) => params?.data?.insurance || "--",
    },
    {
      headerName: "Pre-existing Condition",
      field: "preExistingCondition",
      width: 120,
      resizable: true,
      valueGetter: (params) => params?.data?.preExistingCondition || "--",
    },
    {
      headerName: "Date of Surgery",
      field: "dateOfSurgery",
      width: 120,
      resizable: true,
      valueGetter: (params) => params?.data?.dateOfSurgery || "--",
    },
    {
      headerName: "Record Type",
      width: 100,
      resizable: true,
      cellRendererFramework: (params) => {
        return (
          <BaseButton
            disabled={!params?.data?.pdfRec}
            title="View"
            handleClick={() => setViewRecord(params?.data?.pdfRec)}
            styles={{ color: "#4280FF" }}
          />
        );
      },
    },
    {
      headerName: "Test Results Received",
      field: "testsDone",
      width: 120,
      resizable: true,
      cellRendererFramework: (params) =>
        renderTestResults(params?.data?.testsDone, "testsDone", params.data),
    },
    {
      headerName: "Tests Not Done",
      field: "testsNotDone",
      width: 150,
      resizable: true,
      cellRendererFramework: (params) =>
        renderTestResults(
          params?.data?.testsNotDone,
          "testsNotDone",
          params?.data
        ),
    },
    {
      headerName: "All Tests Needed",
      field: "actualTests",
      width: 150,
      resizable: true,
      cellRendererFramework: (params) =>
        renderTestResults(
          params?.data?.actualTests,
          "actualTests",
          params.data
        ),
    },
    {
      headerName: "Name Of Surgeon",
      field: "nameOfSurgeon",
      width: 150,
      resizable: true,
      valueGetter: (params) => params?.data?.nameOfSurgeon || "--",
    },
    {
      headerName: "Email Of Surgeon",
      field: "emailOfSurgeon",
      width: 150,
      resizable: true,
      valueGetter: (params) => params?.data?.emailOfSurgeon || "--",
    },
    {
      headerName: "Phone Number Of Surgeon",
      field: "phoneOfSurgeon",
      width: 150,
      resizable: true,
      valueGetter: (params) => params?.data?.phoneOfSurgeon || "--",
    },
    {
      headerName: "Next Follow Up Dtae",
      width: 150,
      resizable: true,
      cellRendererFramework: (params) => {
        if (!params?.data || !Array.isArray(params.data.insights)) {
          return "--";
        }

        return params?.data?.insights.length > 0
          ? params?.data?.insights[0]?.value || "--"
          : "--";
      },
    },
    {
      headerName: "Do Not Contact",
      field: "doNotContact",
      width: 150,
      resizable: true,
      valueGetter: (params) => {
        let value = params?.data?.doNotContact;
        return value === undefined || value === null
          ? "--"
          : value
          ? "Yes"
          : "No";
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      resizable: true,
      cellRendererFramework: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.data)}>
            <EditIcon title="Edit Patient" />
          </IconButton>
          <IconButton
            title="Download Report"
            onClick={() => handleDownloadPDF(params.data)}
          >
            <DownloadIcon style={{width:"13px", height:"13px"}} />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          border: "1px solid #E0E0E0",
          borderRadius: 2,
          padding: 2,
        }}
      >
        <PatientTableHeader
          handlePopoverOpen={handlePopoverOpen}
          exportToCSV={exportToCSV}
          exportLoading={exportLoading}
        />

        <Box my={2}>
          <ResizableGrid
            columns={columnsDef}
            allRows={filteredData}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </Box>
      </Box>
      <PatientTableFilters filtersObj={filtersObj} />

      {viewRecord?.length > 0 && (
        <CommonModal
          show={viewRecord?.length > 0}
          body={
            <Box px={3}>
              {viewRecord?.map((record: any) => (
                <Box
                  key={record?.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1">{record?.response}</Typography>
                  {record?.url && (
                    <BaseButton
                      title="View PDF"
                      handleClick={() => window.open(record?.url, "_blank")}
                      styles={{ color: "#4280FF" }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          }
          handleSave={false}
          showSaveButton={false}
          heading={`View Record`}
          size={500}
          toggle={() => setViewRecord([])}
        />
      )}

      {editRecordModal && (
        <CommonModal
          show={editRecordModal}
          body={
            <EditLeadModal formData={formData} handleChange={handleChange} />
          }
          handleSave={handleSave}
          heading={`Edit Record`}
          size={700}
          toggle={() => setEditRecordModal(false)}
          spin={editButtonLoading}
        />
      )}

      {patientPopup && (
        <CommonPopup
          message={`Patient is updated successfully`}
          show={patientPopup}
          toggle={() => setPatientPopup(false)}
          Icon={SuccessIcon}
          heading="Patient updated successfully"
        />
      )}

      {exportPopup && (
        <CommonPopup
          message={`The selected data are exported successfully`}
          show={exportPopup}
          toggle={() => setExportPopup(false)}
          Icon={ExportFileIcon}
          heading="File exported successfully"
        />
      )}
    </>
  );
};

export default PatientTable;

const renderTestResults = (value, key, row: any = {}) => {
  const data = Array.isArray(value) ? value : JSON.parse(value || "[]");
  if (!Array.isArray(data) || data?.length === 0) {
    return "--";
  }

  return (
    <Box sx={{ maxHeight: "100px", ...commonStyles.hideScroll }}>
      {[...new Set(data)]?.map((item, index) => {
        const matchedPdf = row?.pdfRec?.find((pdf) => pdf.response === item);

        return (
          <Typography
            key={index}
            sx={{
              color: "#333333",
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {key === "testsDone" ? (
              <BaseButton
                title={item}
                handleClick={() => window.open(matchedPdf?.url, "_blank")}
                styles={{ color: "#4280FF" }}
              />
            ) : (
              item
            )}
          </Typography>
        );
      })}
    </Box>
  );
};
