import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignForm from "./CampaignForm";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  swalErrorMessage,
  swalSuccessMessage,
  swalWarningMessage,
} from "../../../../Constant/swalMessage";
import { updateCurrentPath } from "../../../../store/actions/root.actions";
import {
  HintIconWithTooltip,
  isEmpty,
  PAGE_SIZE_50,
} from "../../CommonComponents/Common";
import {
  checkQueueAction,
  clearQueueAction,
  getCampaignAction,
  getCampaignCountAction,
  saveCampaignAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import CampaignHeader from "./CampaignHeader";
import CampaignList from "./CampaignList";
import HealthCheck from "./HealthCheck";
import CommonModal from "../../CommonComponents/Modal/CommonModal";
import { Alert, Toast } from "react-bootstrap";
import Draggable from "react-draggable";
import NoticeAlert from "../../Common/Alert/NoticeAlert";
import SummaryCard from "./SummaryCards";
import "../../../../assets/css/page/Campaign.scss";
import CommonPopup from "../../CommonComponents/PopUpCards/CommonPopup";
import { ReactComponent as SuccessIcon } from "../../../../assets/Images/SuccessProjectIcon.svg";
import { getAudienceForSelectAction } from "../../ProjectSection/AudienceSection/Store/Audience.action";
import { getAgentAction } from "../../ProjectSection/Agents/Store/Agent.action";
import { getCallSettingAction } from "../../ProjectSection/CallSettings/Store/callSetting.action";
import {
  getDecryptedItem,
  setEncryptedItem,
} from "../../../../utils/localStorageHelper";

const Campaigns: React.FC = () => {
  const reducer: any = useSelector((state) => state);
  const dispatch: Dispatch<any> = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();

  const {
    CampaignReducer: { campaigns, campaignsCount, campaignDashboardData },
    AccountReducer: { constantData, selectedAccount },
    CallSettingReducer: { callSettings },
  } = reducer;
  const campaignBanner = constantData?.campaignBanner;
  const tempBanner = constantData?.tempBanner;

  let formobj = {
    name: { value: "" },
    audiences: [{ value: "" }],
    selectedAudiences: [],
    range: false,
  };

  const [formState, setFormState] = useState(formobj);
  const { selectedAudiences } = formState;
  const [queueCount, setQueueCount] = useState({ ultra: 0, normal: 0 });
  const [state, setState] = useState<any>({
    show: false,
    pageIndex: Number(getDecryptedItem("campaignpageIndex")) || 1,
    spin: false,
    searchText: getDecryptedItem("campaignSearchText") || "",
    countSpin: false,
    initial: 0,
    star: Boolean(getDecryptedItem("starred")) || false,
  });
  const { show, pageIndex, spin, searchText, countSpin, initial, star } = state;

  const [clearloading, setClearLoading] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignPopup, setCampaignPopup] = useState(false);

  useEffect(() => {
    dispatch(updateCurrentPath("Campaigns", ""));
    const init = async () => {
      setLoading(true);
      const locBool = getDecryptedItem("starred");
      await campaignCall(false || locBool);
      let obj1 = {
        pageIndex: 1,
        pageSize: 100000,
      };
      let res = await dispatch(getAudienceForSelectAction(projectId, obj1));

      setState((prev) => ({
        ...prev,
        audienceArray: res,
      }));
      await dispatch(getAgentAction(projectId));
      await dispatch(getCallSettingAction(projectId));
      setLoading(false);
    };
    init();
  }, []);

  const init = async () => {
    if (!isEmpty(callSettings)) {
      let res: any = await dispatch(checkQueueAction(callSettings?.gateway));
      setQueueCount(res);
    }
  };

  useEffect(() => {
    init();

    let kl = 0;
    if (!isEmpty(callSettings)) {
      if (kl === 0) {
        const interval = setInterval(() => {
          kl = 1;
          init();
        }, 40000);
        return () => clearInterval(interval);
      }
    }
  }, [callSettings]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (initial > 0) {
        if (star) {
          campaignCall(true, true);
        } else {
          campaignCall(false, true);
        }
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const toggle = () => {
    setState((prev) => ({
      ...prev,
      show: !prev.show,
    }));
    setFormState(formobj);
  };

  let obj = {
    formState,
    setFormState,
  };
  const campaignCall = async (starred, pIndex = false) => {
    setEncryptedItem("campaignSearchText", searchText);
    let obj = {
      pageIndex: pageIndex,
      pageSize: PAGE_SIZE_50,
      searchText: searchText,
    };

    if (starred) {
      setEncryptedItem("starred", starred);
      obj["star"] = "true";
      obj["pageIndex"] = 1;
    } else {
      localStorage.removeItem("starred");
    }

    if (pIndex) {
      obj["pageIndex"] = 1;
      setEncryptedItem("campaignpageIndex", "1");
    }

    dispatch(getCampaignCountAction(projectId, obj));
    await dispatch(getCampaignAction(projectId, obj));
    setState((prev) => ({
      ...prev,
      pageIndex: pageIndex,
      initial: 1,
      star: starred ? true : false,
    }));

    if (pIndex) {
      setState((prev) => ({
        ...prev,
        pageIndex: 1,
      }));
    }
  };

  const toggleCampaign = () => {
    setCampaignPopup((prev) => !prev);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (formState.name.value.trim() && selectedAudiences?.length > 0) {
      setState((prev) => ({
        ...prev,
        spin: true,
      }));

      let audiencArray: any = [];
      selectedAudiences.forEach((val: any) => {
        audiencArray.push(val?._id);
      });

      e.preventDefault();
      let obj = {
        campaignName: formState.name.value,
        audienceListId: audiencArray,
        projectId: projectId,
        range: formState.range,
      };
      let res: any = await dispatch(saveCampaignAction(obj));
      let obj1 = {
        pageIndex: 1,
        pageSize: PAGE_SIZE_50,
        searchText: searchText,
      };
      setFormState(formobj);
      await dispatch(getCampaignAction(projectId, obj1));
      dispatch(getCampaignCountAction(projectId, obj1));
      if (res) {
        setCampaignPopup(true);
      }
      setState((prev) => ({
        ...prev,
        spin: false,
        show: false,
        star: false,
      }));
    } else {
      swalWarningMessage("All fields are required");
    }
  };

  const handlePageNavigation = async (page) => {
    setState((prev) => ({
      ...prev,
      countSpin: true,
    }));
    const obj = {
      pageIndex: page,
      pageSize: PAGE_SIZE_50,
      searchText: searchText,
    };
    await dispatch(getCampaignAction(projectId, obj));
    setState((prev) => ({
      ...prev,
      pageIndex: page,
      countSpin: false,
    }));
    setEncryptedItem("campaignpageIndex", page);
  };

  const calculateQueue = (type) => {
    let val = Number(queueCount[type]) / 2 / 60 + 1;
    return queueCount[type] > 0 ? `${Math.ceil(val)} minutes` : `No Queue`;
  };

  const clearQueue = async (status) => {
    const isClearOnly = status === "clear";
    const confirmationMessage = isClearOnly
      ? "This action will clear all calls currently in the queue. You will not have access to this call data anywhere. Are you sure you want to proceed?"
      : "This action will clear all calls in the queue and also disable the selected gateway. You will not have access to this call data anywhere. Are you sure you want to proceed?";
    const denyButtonText = isClearOnly ? "Clear" : "Clear and Disable";

    const result = await Swal.fire({
      title: "Warning!",
      text: confirmationMessage,
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Cancel",
      denyButtonText,
      denyButtonColor: "#7066e0",
      confirmButtonColor: "#dc3741",
    });

    if (result?.isConfirmed) return false;
    if (result?.isDenied) {
      const obj = {
        gateway_name: callSettings?.gateway,
        disable: !isClearOnly,
      };
      isClearOnly ? setClearLoading(true) : setDisableLoading(true);
      try {
        const res: any = await dispatch(clearQueueAction(obj));
        if (res?.status === 200) {
          swalSuccessMessage(isClearOnly ? "Cleared" : "Disabled");
        }
      } catch (error) {
        console.error("Error :", error);
        swalErrorMessage("An unexpected error occurred.");
      }
      isClearOnly ? setClearLoading(false) : setDisableLoading(false);
    }
  };

  return (
    <Box pb={2}>
      {campaignBanner?.display && (
        <Alert key={"danger"} variant={"danger"}>
          <div
            style={{
              width: "99%",
              wordWrap: "break-word",
            }}
          >
            <p style={{ color: "white" }}> {campaignBanner?.msg} </p>
          </div>
        </Alert>
      )}
      {tempBanner?.display && <NoticeAlert content={tempBanner?.msg} />}
      {(Number(queueCount?.normal) > 0 || Number(queueCount?.ultra) > 0) && (
        <Draggable>
          <div style={{ position: "relative", zIndex: 1050, cursor: "grab" }}>
            <Toast
              style={{
                position: "absolute",
                top: 90,
                right: 10,
                zIndex: 100,
              }}
            >
              <Toast.Header closeButton={false}>
                <strong className="mr-auto">
                  Calls in queue{" "}
                  <HintIconWithTooltip
                    content={
                      "Currently, there is a queue for calls, and we appreciate your patience. You will receive a call once the queue is completed."
                    }
                    position="left"
                  />
                </strong>{" "}
              </Toast.Header>
              <Toast.Body>
                Ultra Realistic Voices : {queueCount?.ultra}
                <br />
                <span>Estimated time to clear : {calculateQueue("ultra")}</span>
                <hr />
                Normal Voices : {queueCount?.normal} <br />
                <span>
                  Estimated time to clear : {calculateQueue("normal")}
                </span>
                {selectedAccount?.params?.queueClear && (
                  <div className="queue-container">
                    <button
                      disabled={clearloading}
                      className="queue-btn"
                      onClick={() => clearQueue("clear")}
                    >
                      {clearloading ? "Clearing..." : "Clear"}
                    </button>

                    <button
                      disabled={disableLoading}
                      className="queue-btn emergency"
                      onClick={() => clearQueue("disable")}
                    >
                      {disableLoading ? "Disabling..." : "Disable"}
                    </button>
                  </div>
                )}
              </Toast.Body>
            </Toast>
          </div>
        </Draggable>
      )}

      <CampaignHeader
        toggle={toggle}
        searchText={searchText}
        setState={setState}
        star={star}
        campaignCall={campaignCall}
      />

      <Box
        py={3}
        display="grid"
        gridTemplateColumns="repeat(7, 1fr)"
        gap={2}
        sx={{
          "@media (max-width: 1300px)": {
            gridTemplateColumns: "repeat(6, 1fr)",
          },
          "@media (max-width: 1200px)": {
            gridTemplateColumns: "repeat(5, 1fr)",
          },
          "@media (max-width: 1020px)": {
            gridTemplateColumns: "repeat(4, 1fr)",
          },
        }}
      >
        <SummaryCard
          title="Total"
          value={campaignsCount || 0}
          circleColor="linear-gradient(180deg, rgba(255, 150, 143, 0.8) 0%, rgba(226, 76, 66, 0.8) 100%)"
        />
        <SummaryCard
          title=" Campaign Runs"
          value={campaignDashboardData?.campaignRun || 0}
          circleColor="linear-gradient(237.96deg, rgba(150, 255, 143, 0.8) -12.91%, rgba(87, 209, 78, 0.8) 100.74%)"
        />
        <SummaryCard
          title=" Calls Made"
          value={campaignDashboardData?.campaignRunDetails || 0}
          circleColor="linear-gradient(122.19deg, rgba(255, 143, 224, 0.8) 34.92%, rgba(242, 93, 203, 0.8) 100.02%)"
        />
      </Box>

      <CampaignList
        run={campaignBanner?.display}
        countSpin={countSpin}
        pageIndex={pageIndex}
        star={star}
        handlePageNavigation={handlePageNavigation}
        searchText={searchText}
      />
      <HealthCheck loading={loading} />

      {show && (
        <CommonModal
          show={show}
          toggle={toggle}
          heading="Add Campaign"
          body={<CampaignForm obj={obj} />}
          size={600}
          handleSave={handleSave}
          spin={spin}
        />
      )}
      {campaignPopup && (
        <CommonPopup
          message={""}
          show={campaignPopup}
          toggle={toggleCampaign}
          Icon={SuccessIcon}
          heading="Campaign created successfully"
        />
      )}
    </Box>
  );
};

export default Campaigns;
