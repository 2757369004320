import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { Pie, Line } from "react-chartjs-2";
import moment from "moment";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  TimeScale
);

const DashboardCharts = ({ data }) => {
  const insuranceCount = data?.reduce((acc, row) => {
    if (row.insurance) acc[row?.insurance] = (acc[row?.insurance] || 0) + 1;
    return acc;
  }, {});

  const surgeryByDate = data.reduce((acc, row) => {
    if (row["dateOfSurgery"]) {
      const date = moment(row["dateOfSurgery"]).format("MM-DD");
      acc[date] = (acc[date] || 0) + 1;
    }
    return acc;
  }, {});

  const today = moment();
  const last7Days = Array.from({ length: 7 }, (_, i) =>
    today.clone().subtract(i, "days").format("MM-DD")
  ).reverse();

  const allDates = [
    ...new Set([...last7Days, ...Object.keys(surgeryByDate)]),
  ].sort();
  const surgeryData = allDates?.map((date) => surgeryByDate[date] || 0);

  const surgeryDateChart = {
    labels: allDates,
    datasets: [
      {
        label: "Surgeries",
        data: surgeryData,
        borderColor: "#FF5E00BF",
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Paper
            sx={{
              padding: 2,
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography textTransform="capitalize" variant="h6">
              Surgeries Over Time
            </Typography>
            <Box sx={{ flex: 1 }}>
              <Line
                data={surgeryDateChart}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        stepSize: 1,
                        precision: 0,
                      },
                    },
                  },
                }}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Paper
            sx={{
              padding: 2,
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography textTransform="capitalize" variant="h6">
              Insurance Distribution
            </Typography>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pie
                height={100}
                data={{
                  labels: Object.keys(insuranceCount),
                  datasets: [
                    {
                      label: "Insurance",
                      data: Object.values(insuranceCount),
                      backgroundColor: [
                        "#A5D6A7",
                        "#FFCCBC",
                        "#90CAF9",
                        "#FFF59D",
                        "#CE93D8",
                      ],
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: { display: false },
                  },
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardCharts;
