import CryptoJS from "crypto-js";
const SECRET_KEY = "5b7b7e70-fba8-4e15-89cc-c4b7ee4e6482";

export const setEncryptedItem = (key, value) => {
  const encryptedValue = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    SECRET_KEY
  ).toString();
  localStorage.setItem(key, encryptedValue);
};
export const getDecryptedItem = (key) => {
  const encryptedValue = localStorage.getItem(key);
  if (!encryptedValue) return null;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};
