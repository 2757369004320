import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Info } from "../../../../assets/icons/info.svg";
import * as FileSaver from "file-saver";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  converTime,
  isEmpty,
  LoadingSlides,
  PAGE_SIZE_50,
} from "../../CommonComponents/Common";
import { getAllCampaignDetailsAction, exportAllCampaignRunsAction } from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import CommonModal from "../../CommonComponents/Modal/CommonModal";
import Insight from "../CampaignDetails/Insight";
import SummaryCard from "../Campaign/SummaryCards";
import FilterBody from "../CampaignDetails/Filter/FilterBody";
import DynamicTable from "../../CommonComponents/Table/DynamicTable";
import StatusButton from "../../Common/Buttons/StatusButton";
import chroma from "chroma-js";
import BaseButton from "../../Common/Buttons/BaseButton";
import CallTranscriptModal from "../../CallHistory/CallTranscriptModal";
import SearchBar from "../../Common/Search/SearchBar";
import useDebounce from "../../../Hooks/useDebounce";
import ReactAudioPlayer from "react-audio-player";

import { ReactComponent as MenuDots } from "../../../../assets/Images/MenuDots.svg";
import { ReactComponent as BackIcon } from "../../../../assets/Images/WhiteBackIcon.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/Images/FilterIcon.svg";
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import { styles } from "../../../../assets/MUIStyles/campaignDetailsList";
import {
  getDecryptedItem,
  setEncryptedItem,
} from "../../../../utils/localStorageHelper";

function AllCampaignRuns(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  const campaignRuns = locSearch.get("campaignRuns");
  const campaignId = locSearch.get("campaign-id");
  const [loading, setLoading] = useState(false);
  const reducer: any = useSelector((state) => state);

  const {
    AccountReducer: { constantData },
    CampaignReducer: {
      allcampaignsDetails,
      allcampaignsDetailsCounts,
      allcampaignsDetailsTotalCounts,
    },
  } = reducer;

  const [state, setState] = useState<any>({
    CALL_STATUS: constantData?.callStatus,
    campaignRunListData: [],
    showCreateButton: false,
    exportSpin: false,
    summaryModal: false,
    recordingUrl: "",
    selectedCampaignRun: "",
    filterDrawer: false,
    countObj: {},
    insightsPopupView: false,
    trackEnabledRecording: [],
    pageIndex: Number(getDecryptedItem("allcampaignrunspageIndex")) || 1,
    durationFilter: "",
    statusFilter: [],
    outComefilter: [],
    searchText: "",
    min: "",
    max: "",
  });
  const {
    campaignRunListData,
    selectedCampaignRun,
    exportSpin,
    summaryModal,
    filterDrawer,
    countObj,
    insightsPopupView,
    trackEnabledRecording,
    pageIndex,
    durationFilter,
    statusFilter,
    outComefilter,
    CALL_STATUS,
    searchText,
  } = state;
  const [anchorEl, setAnchorEl] = useState(null);

  const debouncedSearchTerm = useDebounce(searchText, 3000);

  const {
    AccountReducer: { selectedAccount },
    RecordingReducer: { recordings },
  } = reducer;

  const callFn = async (searchTerm = "") => {
    setLoading(true);

    const obj = {
      pageIndex: pageIndex,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      searchText: searchTerm,
    };

    await dispatch(
      getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: "true" })
    );
    setLoading(false);
    await dispatch(
      getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: false })
    );
  };

  useEffect(() => {
    callFn(debouncedSearchTerm);
  }, [dispatch, debouncedSearchTerm]);

  useEffect(() => {
    if (allcampaignsDetails) {
      setState((prev) => ({
        ...prev,
        campaignRunListData: allcampaignsDetails,
      }));
    }
  }, [allcampaignsDetails]);
  useEffect(() => {
    if (allcampaignsDetailsCounts?.length > 0) {
      let countsData = {};

      for (const status of allcampaignsDetailsCounts) {
        countsData[status._id] = status.count;
      }
      setState((prev) => ({
        ...prev,
        countObj: countsData,
      }));
    }
  }, [allcampaignsDetailsCounts]);

  useEffect(() => {
    if (recordings?.length > 0) {
      let arr: any = [];
      for (const record of recordings) {
        if (record?.track === true) {
          arr.push({
            name: record.recordingName,
            tag: record.tag,
          });
        }
      }
      setState((prev) => ({
        ...prev,
        trackEnabledRecording: arr,
      }));
    }
  }, [recordings]);

  const toggle = (name = "summaryModal") => {
    setState((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const getOutcome = (data) => {
    let trackResult: any = [];
    if (data?.outComes) {
      data.outComes.forEach((da) => {
        let kl = trackEnabledRecording.find((kl) => kl.tag === da);
        if (kl) {
          trackResult.push(kl.name);
        }
      });

      trackEnabledRecording?.forEach((track) => {
        if (data.outComes.includes(track.tag)) {
          trackResult.push(track.name);
        }
      });
      return trackResult.length > 0 ? trackResult[trackResult?.length - 1] : "";
    } else {
      return "";
    }
  };
  const getName = (phone) => {
    return `${phone?.customParameters?.firstName || "No Name"} ${
      phone?.customParameters?.lastName || ""
    }`;
  };

  const openModal = (selectedCampaignRun) => {
    setState((prev) => ({
      ...prev,
      summaryModal: true,
      recordingUrl: selectedCampaignRun.recordingUrl,
      selectedCampaignRun: selectedCampaignRun,
    }));
  };

  const popover = (data) => {
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Call Information</Popover.Title>
        <Popover.Content>
          Call Reference Id : <strong>{data}</strong>
        </Popover.Content>
      </Popover>
    );
  };

  const ExportCSV = async () => {
    setState((prev) => ({
      ...prev,
      exportSpin: true,
    }));

    let obj = {
      pageIndex: 1,
      pageSize: 1000,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      searchText: searchText,
      isDocsOnly: "true",
    };
    let res: any = await dispatch(exportAllCampaignRunsAction(campaignId, obj));

    let matchArr = [
      "mandatory_params",
      "firstName",
      "lastName",
      "FirstName",
      "LastName",
      "phone",
      "PhoneNum",
    ];
    if (res) {
      let arr: any = [];
      res.forEach((kl) => {
        let copy = kl?.customParameters;
        let obj: any = {};
        obj["First Name"] = copy?.FirstName || "--";
        obj["Last Name"] = copy?.LastName || "--";
        obj["Phone number"] = kl?.phone || "--";
        obj["Calling Status"] = CALL_STATUS[kl.callStatus] || "";
        obj["Call UUID"] = kl?.call_uuid || "";
        obj["Cost"] = kl?.newCost || "";
        if (selectedAccount?.params?.pricesWaivers) {
          obj["Cost"] = "--";
        }
        obj["Recording Url"] = kl?.callRecordingUrl || "";
        if (!isEmpty(copy)) {
          for (const customKeys in copy) {
            if (!matchArr.includes(customKeys)) {
              obj[customKeys] = copy[customKeys];
            }
          }
        }

        arr.push(obj);
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(arr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Campaign Report ` + fileExtension);
    }
    setState((prev) => ({
      ...prev,
      exportSpin: false,
    }));
  };

  const obj = {
    projectId,
    searchText,
    setMainState: setState,
    trackEnabledRecording,
    mainState: state,
    campaignRuns,
    campaignId,
  };

  const handlePageNextInScroll = async (index) => {
    let obj = {
      pageIndex: index,
      pageSize: PAGE_SIZE_50,
      live: false,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      isScrolled: false,
      searchText: searchText,
    };
    await dispatch(
      getAllCampaignDetailsAction(campaignId, { ...obj, isDocsOnly: "true" })
    );
    setState((prev) => ({
      ...prev,
      pageIndex: index,
    }));
    setEncryptedItem("allcampaignrunspageIndex", index);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return (
      <Box height={"90vh"} width={"100%"} className="flex-xy-center">
        <LoadingSlides bgColor="#FF5E00" />
      </Box>
    );
  }

  return (
    <>
      <Box className="flex-between header" gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography className="font-20-grey">Campaign List</Typography>
        </Box>

        <Box className="flex-align-center" gap={1}>
          <SearchBar
            searchText={searchText}
            handleChange={(e) => {
              e.persist();
              setState((prev) => ({
                ...prev,
                searchText: e.target.value,
              }));
            }}
          />

          <Box>
            <BaseButton
              title={<MenuDots title="More" />}
              variant="outlined"
              handleClick={handleClick}
              styles={styles.iconBtn}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: styles.menu,
              }}
            >
              <MenuItem
                sx={styles.menuItem}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    insightsPopupView: true,
                  }));
                  setAnchorEl(null);
                }}
              >
                View Insight
              </MenuItem>
              <MenuItem sx={styles.menuItem} onClick={ExportCSV}>
                <ExportIcon />
                <Typography ml={1.2} className="font-14-grey3">
                  Export File
                </Typography>
              </MenuItem>
            </Menu>
          </Box>

          <BaseButton
            title={<FilterIcon title="Apply Filters" />}
            variant="outlined"
            handleClick={() => toggle("filterDrawer")}
            styles={styles.iconBtn}
          />

          <BaseButton
            title="Back"
            variant="contained"
            handleClick={() =>
              history.push(
                `/account/project/${projectId}/campaign-runs?campaignid=${campaignId}`
              )
            }
            startIcon={<BackIcon className="back-icon" />}
          />
        </Box>
      </Box>
      <Box
        py={3}
        display="grid"
        gridTemplateColumns="repeat(7, 1fr)"
        gap={2}
        sx={{
          "@media (max-width: 1300px)": {
            gridTemplateColumns: "repeat(6, 1fr)",
          },
          "@media (max-width: 1200px)": {
            gridTemplateColumns: "repeat(5, 1fr)",
          },
          "@media (max-width: 1020px)": {
            gridTemplateColumns: "repeat(4, 1fr)",
          },
        }}
      >
        <SummaryCard
          title="Calls Failed"
          value={countObj["704"] || 0}
          circleColor="linear-gradient(180deg, rgba(255, 150, 143, 0.8) 0%, rgba(226, 76, 66, 0.8) 100%)"
        />
        <SummaryCard
          title="Calls Connected"
          value={countObj["703"] || 0}
          circleColor="linear-gradient(237.96deg, rgba(150, 255, 143, 0.8) -12.91%, rgba(87, 209, 78, 0.8) 100.74%)"
        />
        <SummaryCard
          title=" Calls Made"
          value={allcampaignsDetailsTotalCounts || 0}
          circleColor="linear-gradient(122.19deg, rgba(255, 143, 224, 0.8) 34.92%, rgba(242, 93, 203, 0.8) 100.02%)"
        />
      </Box>

      <Box mb={2}>
        <DynamicTable
          columns={[
            {
              label: "Phone Number",
              render: (campaign) => {
                return (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {getName(campaign)}
                      </Tooltip>
                    }
                    rootClose={true}
                    rootCloseEvent={"click"}
                  >
                    <span className="d-inline-block">
                      <span style={{ pointerEvents: "none" }}>
                        {campaign.phone}{" "}
                      </span>
                    </span>
                  </OverlayTrigger>
                );
              },
            },
            {
              label: "Calling Status",
              render: ({ callStatus }) => {
                const status = constantData["callStatus"][callStatus];
                let statusColor =
                  constantData["callStatusColour"][callStatus] || "#333333";

                try {
                  statusColor = chroma(statusColor).hex();
                } catch (error) {
                  statusColor = "#333333";
                }
                const backgroundColor = chroma(statusColor).alpha(0.1).hex();

                return (
                  <StatusButton
                    status={status}
                    statusColor={statusColor}
                    backgroundColor={backgroundColor}
                  />
                );
              },
            },
            {
              label: trackEnabledRecording?.length > 0 ? "Outcomes" : "UUID",
              render: (campaign) =>
                trackEnabledRecording?.length > 0
                  ? getOutcome(campaign)
                  : campaign?.call_uuid && (
                      <OverlayTrigger
                        trigger="click"
                        placement="right"
                        overlay={popover(campaign?.call_uuid)}
                        rootClose={true}
                        rootCloseEvent={"click"}
                      >
                        <IconButton title="UUID">
                          <Info />
                        </IconButton>
                      </OverlayTrigger>
                    ),
            },
            {
              label: "Call Cost",
              render: ({ newCost }) =>
                selectedAccount?.params?.pricesWaivers ? "--" : newCost || "--",
            },

            {
              label: "Call Summary",
              render: (campaign) => {
                return (
                  <BaseButton
                    disabled={!["703", "712"].includes(campaign?.callStatus)}
                    title="View Transcript"
                    handleClick={() => openModal(campaign)}
                    styles={{ color: "#4280FF" }}
                  />
                );
              },
            },

            {
              label: "Recording",
              render: ({ callRecordingUrl, call_uuid }) => {
                return (
                  <ReactAudioPlayer
                    src={
                      callRecordingUrl ||
                      `https://storage.googleapis.com/botstream-recordings/${call_uuid}.mp3`
                    }
                    controls
                    style={{
                      minWidth: "40px",
                      height: "30px",
                    }}
                  />
                );
              },
            },
            {
              label: "Campaign Run Time",
              render: ({ createdAt }) => converTime(createdAt),
              styles: { textTransform: "none" },
            },
          ]}
          data={campaignRunListData}
          loading={loading}
          totalPages={Math.ceil(
            Number(allcampaignsDetailsTotalCounts) / PAGE_SIZE_50
          )}
          currentPage={Number(pageIndex)}
          handlePageChange={(e, v) => {
            handlePageNextInScroll(v);
          }}
          handlePrevious={() => handlePageNextInScroll(pageIndex - 1)}
          handleNext={() => handlePageNextInScroll(pageIndex + 1)}
          noDataAvailableMessage="No Campaigns Available"
          rowCount={campaignRunListData?.length}
          totalRowCount={allcampaignsDetailsTotalCounts}
        />
      </Box>

      {filterDrawer && (
        <Drawer
          anchor={"right"}
          open={filterDrawer}
          onClose={() => toggle("filterDrawer")}
        >
          <FilterBody all={true} toggle={toggle} obj={obj} />
        </Drawer>
      )}

      {summaryModal && (
        <CallTranscriptModal
          show={summaryModal}
          onClose={() => toggle("summaryModal")}
          selectedTranscript={selectedCampaignRun}
        />
      )}

      {insightsPopupView && (
        <CommonModal
          show={insightsPopupView}
          toggle={() =>
            setState((prev) => ({ ...prev, insightsPopupView: false }))
          }
          size={900}
          handleSave={() => {}}
          heading="View Insight"
          body={<Insight campaignName={""} all={true} />}
        />
      )}
    </>
  );
}

export default AllCampaignRuns;
