import React from "react";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import PatientTable from "./PatientTable";
import DashboardCharts from "./DashboardCharts";
import SummaryCard from "../Dashboard/SummaryCard";
import { useSelector } from "react-redux";

const Dashboard = ({ pageIndex, setPageIndex }) => {
  const reducer = useSelector((reducer: any) => reducer);
  const {
    ProjectReducer: { leadsList: data },
  } = reducer;
  const totalPatients = data?.length;
  const surgeriesPerformed = data?.filter((row) => row["dateOfSurgery"]).length;
  const pendingTests = data.filter(
    (row) => row["testResult"] === "Pending"
  ).length;
  const insuranceCount = [...new Set(data?.map((row) => row?.Insurance))]?.length;
  const today = moment().format("YYYY-MM-DD");
  const patientsToday = data?.filter(
    (row) =>
      row["createdAt"] &&
      moment(row["createdAt"]).format("YYYY-MM-DD") === today
  ).length;

  const summaryCards = [
    {
      title: "Total Patients",
      circleColor:
        "linear-gradient(180deg, rgba(22, 200, 199, 0.8) 0%, rgba(106, 233, 233, 0.8) 100%)",
      value: totalPatients,
    },
    {
      title: "Surgeries",
      circleColor: "linear-gradient(268.11deg, #FFDA8F 9.11%, #EEB644 105.62%)",
      value: surgeriesPerformed,
    },
    // {
    //   title: "Pending Tests",
    //   circleColor: "linear-gradient(60.26deg, #79EE81 15.53%, #26C231 89.39%)",
    //   value: pendingTests,
    // },
    {
      title: "Insurance Providers",
      circleColor: "linear-gradient(180deg, #8FA8FF 0%, #4265E2 100%)",
      value: insuranceCount,
    },
    {
      title: "Patients Today",
      circleColor:
        "linear-gradient(122.19deg, rgba(255, 143, 224, 0.8) 34.92%, rgba(242, 93, 203, 0.8) 100.02%)",
      value: patientsToday,
    },
  ];

  return (
    <Box>
      <Box
        py={3}
        display="grid"
        gridTemplateColumns="repeat(5, 1fr)"
        gap={2}
        sx={{
          "@media (max-width: 1250px)": {
            gridTemplateColumns: "repeat(4, 1fr)",
          },
          "@media (max-width: 1100px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          "@media (max-width: 800px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          "@media (max-width: 600px)": {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
        }}
      >
        {summaryCards.map(({ title, circleColor, value }, index) => (
          <Grid item xs={12} md={3} key={index}>
            <SummaryCard
              title={title}
              circleColor={circleColor}
              value={value}
            />
          </Grid>
        ))}
      </Box>

      <DashboardCharts data={data} />
      <PatientTable pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </Box>
  );
};
export default Dashboard;
