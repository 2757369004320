import React from "react";

const MedicalReport = ({ patientData }) => {
  return (
    <div
      style={{
        width: "950px",
        margin: "0 auto",
        padding: "10px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      {/* Header with UCLA Health logo and patient label */}
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "middle" }}>
              <div
                style={{
                  display: "inline-block",
                  height: "40px",
                  width: "100px",
                  fontWeight: "bold",
                  fontSize: "30px",
                  backgroundColor: "#0071bc",
                  padding: "5px 10px",
                  color: "#ffffff",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                UCLA
              </div>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginLeft: "10px",
                }}
              >
                Health
              </span>
            </td>
            <td style={{ width: "280px", verticalAlign: "top" }}>
              <div
                style={{
                  border: "1px solid lightgrey",
                  width: "280px",
                  height: "120px",
                  padding: "10px",
                  fontSize: "14px",
                  color: "lightgray",
                }}
              >
                MRN: {patientData?._id}
                <br />
                Patient Name: {patientData?.firstName || ""}{" "}
                {patientData?.lastName || ""}
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    marginTop: "70px",
                    display: "inline-block",
                    marginLeft: "100px",
                  }}
                >
                  (Patient Label)
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Title Section */}
      <div>
        <p style={{ fontWeight: "bold", fontSize: "25px", marginBottom: "0" }}>
          HISTORY & PHYSICAL
        </p>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "25px",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          SHORT FORM / PROCEDURE
        </p>
        <p style={{ color: "red", fontSize: "17px", marginTop: "5px" }}>
          Unapproved Abbreviations - Do Not Use: U or IU, Trailing Zeros (1.0)
          or
          <br />
          Leading Zeros (.1), Ms or MsO4, MgSO4, DA or Dop, DB, or Dob, QD or
          QOD, SNP
        </p>
      </div>

      {/* Form Section */}
      <div style={{ marginTop: "10px" }}>
        <p>
          DATE: ____________ TIME: __________ PROCEDURE:{" "}
          {"_______________________"}
        </p>
        <p>
          DIAGNOSIS / INDICATION:{" "}
          {"________________________________________________"}
        </p>
        <p>ALLERGIES: {"________________________________________________"}</p>
      </div>

      {/* Checkbox Section */}
      <div style={{ marginTop: "20px" }}>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input
            type="checkbox"
            style={{ marginRight: "8px", verticalAlign: "middle" }}
          />
          INFORMED CONSENT OBTAINED INCLUDING RISKS BENEFITS AND ALTERNATIVES
        </label>
        <label style={{ display: "block", marginBottom: "5px" }}>
          <input
            type="checkbox"
            style={{ marginRight: "8px", verticalAlign: "middle" }}
          />
          INFORMED CONSENT OBTAINED FOR SEDATION INCLUDING RISKS BENEFITS AND/OR
          ALTERNATIVES
        </label>
        <label style={{ display: "block" }}>
          <input
            type="checkbox"
            style={{ marginRight: "8px", verticalAlign: "middle" }}
          />
          INFORMED CONSENT OBTAINED FOR BLOOD TRANSFUSIONS INCLUDING RISKS
          BENEFITS AND/OR ALTERNATIVES
        </label>
      </div>

      {/* Main Two-Column Content */}
      <table
        style={{ width: "100%", marginTop: "20px", borderCollapse: "collapse" }}
      >
        <tbody>
          <tr>
            {/* Left Column */}
            <td
              style={{
                width: "50%",
                verticalAlign: "top",
                borderBottom: "1px solid black",
              }}
            >
              <p style={{ fontWeight: "bold" }}>
                CHIEF COMPLAINT : {"_________________________________"}
              </p>

              <p>HISTORY OF PRESENT ILLNESS :</p>
              <p>{"___________________________________"}</p>
              <p>{"___________________________________"}</p>
              <p>
                {patientData?.illnessHistory3 ||
                  "___________________________________"}
              </p>
              <p>{"___________________________________"}</p>

              <p>CURRENT MEDICATION :</p>
              <p>{"___________________________________"}</p>
              <p>{"___________________________________"}</p>

              <p>FAMILY HISTORY :</p>
              <p>{"___________________________________"}</p>
              <p>{"___________________________________"}</p>

              <p>SOCIAL HISTORY :</p>
              <p>{"___________________________________"}</p>
              <p>{"___________________________________"}</p>

              <div>
                <span>HISTORY OF BLEEDING :</span>
                <span style={{ marginLeft: "24px" }}>
                  <input
                    type="checkbox"
                    style={{ marginRight: "5px", verticalAlign: "middle" }}
                  />
                  <span>YES</span>
                </span>
                <span style={{ marginLeft: "14px" }}>
                  <input
                    type="checkbox"
                    style={{ marginRight: "5px", verticalAlign: "middle" }}
                  />
                  <span>NO</span>
                </span>
              </div>

              <p>PAST SURGICAL HISTORY :</p>
              <p>{"___________________________________"}</p>
              <p>{"___________________________________"}</p>

              {/* Physical Exam Table */}
              <table
                style={{
                  width: "100%",
                  marginTop: "20px",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      EYES <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      GU <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      ENT <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      GYN <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      CARDIAC <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      MUSCULOSKELETAL <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      RESPIRATORY <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      SKIN <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      GI <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                    <td style={{ border: "1px solid black", padding: "5px" }}>
                      NEURO <br />
                      <label
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        WNL
                      </label>
                      <label style={{ display: "inline-block" }}>
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "5px",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        ABNORMAL
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            {/* Right Column */}
            <td
              style={{
                width: "50%",
                verticalAlign: "top",
                borderLeft: "1px solid black",
                borderBottom: "1px solid black",
                paddingLeft: "10px",
              }}
            >
              <p style={{ fontWeight: "bold" }}>PHYSICAL EXAM</p>
              <p>*VITAL SIGNS: BP _____/_____ T _______ P ______ R ______</p>

              <div>
                <div>
                  <span style={{ textDecoration: "underline" }}>
                    NORMAL EXAM
                  </span>
                  <span
                    style={{ textDecoration: "underline", marginLeft: "35px" }}
                  >
                    APPLICATION FINDINGS
                  </span>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <div>
                    <label style={{ display: "inline-block" }}>
                      <input
                        type="checkbox"
                        style={{ marginRight: "5px", verticalAlign: "middle" }}
                      />{" "}
                      GENERAL*
                    </label>
                    <span style={{ marginLeft: "50px" }}>
                      _______________________________
                    </span>
                  </div>
                  <div>
                    <label style={{ display: "inline-block" }}>
                      <input
                        type="checkbox"
                        style={{ marginRight: "5px", verticalAlign: "middle" }}
                      />{" "}
                      HEENT*
                    </label>
                    <span style={{ marginLeft: "72px" }}>
                      ______________________________
                    </span>
                  </div>
                  <div>
                    <label style={{ display: "inline-block" }}>
                      <input
                        type="checkbox"
                        style={{ marginRight: "5px", verticalAlign: "middle" }}
                      />{" "}
                      HEART*†
                    </label>
                    <span style={{ marginLeft: "64px" }}>
                      _______________________________
                    </span>
                  </div>
                  <div>
                    <label style={{ display: "inline-block" }}>
                      <input
                        type="checkbox"
                        style={{ marginRight: "5px", verticalAlign: "middle" }}
                      />{" "}
                      LUNGS*†
                    </label>
                    <span style={{ marginLeft: "64px" }}>
                      ______________________________
                    </span>
                  </div>
                  <div>
                    <label style={{ display: "inline-block" }}>
                      <input
                        type="checkbox"
                        style={{ marginRight: "5px", verticalAlign: "middle" }}
                      />{" "}
                      ABDOMEN*
                    </label>
                    <span style={{ marginLeft: "49px" }}>
                      ______________________________
                    </span>
                  </div>
                  <div>
                    <label style={{ display: "inline-block" }}>
                      <input
                        type="checkbox"
                        style={{ marginRight: "5px", verticalAlign: "middle" }}
                      />{" "}
                      EXTREMITIES
                    </label>
                    <span style={{ marginLeft: "45px" }}>
                      ____________________________
                    </span>
                  </div>
                  <div>
                    <label style={{ display: "inline-block" }}>
                      <input
                        type="checkbox"
                        style={{ marginRight: "5px", verticalAlign: "middle" }}
                      />{" "}
                      GENITALIA*
                    </label>
                    <span style={{ marginLeft: "40px" }}>
                      ______________________________
                    </span>
                  </div>
                  <div>
                    <label style={{ display: "inline-block" }}>
                      <input
                        type="checkbox"
                        style={{ marginRight: "5px", verticalAlign: "middle" }}
                      />{" "}
                      NEURO*
                    </label>
                    <span style={{ marginLeft: "60px" }}>
                      _______________________________
                    </span>
                  </div>
                </div>
              </div>

              {/* Airway Assessment */}
              <div style={{ marginTop: "20px" }}>
                <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  AIRWAY ASSESSMENT †:
                </p>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <tbody>
                    <tr>
                      <th style={{ width: "30px", textAlign: "left" }}>YES</th>
                      <th style={{ width: "30px", textAlign: "left" }}>NO</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          style={{ verticalAlign: "middle" }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ verticalAlign: "middle" }}
                        />
                      </td>
                      <td>Unable to visualize uvula</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          style={{ verticalAlign: "middle" }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ verticalAlign: "middle" }}
                        />
                      </td>
                      <td>
                        C-spine precautions or unable to flex / extend neck
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          style={{ verticalAlign: "middle" }}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ verticalAlign: "middle" }}
                        />
                      </td>
                      <td>
                        Sleep apnea confirmed by sleep study or on CPAP at home
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* ASA Classification */}
              <div style={{ marginTop: "20px" }}>
                <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                  ASA CLASSIFICATION †:
                </p>
                <div>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                    />
                    A normal, healthy patient (A).
                  </label>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                    />
                    A patient with mild systemic disease (B).
                  </label>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                    />
                    A patient with severe systemic disease that limits activity
                    but is not incapacitating (C).
                  </label>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                    />
                    A patient with an incapacitating systemic disease that is a
                    constant threat to life (D).
                  </label>
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    <input
                      type="checkbox"
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                    />
                    A moribund patient who is agonal, not expected to live 24
                    hours, with or without operation; always considered an
                    emergency (E).
                  </label>
                </div>
                <p style={{ marginTop: "10px" }}>
                  Follow each CLASS with a (E) to denote emergency operation.
                </p>
                <p style={{ fontWeight: "bold", marginTop: "10px" }}>
                  * REQUIRED PHYSICAL EXAM ELEMENTS FOR H&P
                </p>
                <p style={{ fontWeight: "bold" }}>
                  † REQUIRED EXAM ELEMENTS FOR ALL SEDATION
                </p>
                <p style={{ fontWeight: "bold", marginLeft: "12px" }}>
                  WITHOUT AN ANESTHESIOLOGIST PRESENT
                </p>
                <p style={{ fontWeight: "bold" }}>
                  * REQUIRED PHYSICAL EXAM ELEMENTS FOR H&P
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Footer */}
      <p style={{ fontWeight: "bold", marginTop: "20px" }}>
        I have reviewed the history and physical and have determined this
        Patient to be an appropriate candidate to undergo the planned procedure
        with sedation and analgesia.
      </p>
      <p>
        MD Signature ________________ &nbsp; ID # {"_________"} &nbsp; Date
        _______________ &nbsp; Time ________________
      </p>
      <div style={{ marginTop: "10px" }}>
        <span style={{ fontSize: "12px" }}>UCLA Form #201539 Rev (10/14)</span>
        <span style={{ fontSize: "12px", float: "right" }}>Page 1 of 1</span>
      </div>
    </div>
  );
};

export default MedicalReport;
