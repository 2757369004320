import React from "react";
import {
  Box,
  Typography,
  Popover,
  MenuItem,
  Divider,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import {
  clearAccountReducerAction,
  getEncryptionPublicKeyAction,
  logoutAction,
} from "../../Store/Account/Account.action";
import { clearProjectReducer } from "../../Projects/Store/projects.action";
import { clearCallSettingReducerAction } from "../../ProjectSection/CallSettings/Store/callSetting.action";
import { clearAudienceReducer } from "../../ProjectSection/AudienceSection/Store/Audience.action";
import { useHistory } from "react-router-dom";
import UpdateProfile from "./UpdateProfile";
import { shortenString } from "../../../../Constant/helper";
import { clearCampaignReducerAction } from "../../ProjectSection/CampaignSection/Store/Campaign.action";

const EmailMenu = ({ obj }) => {
  const {
    formData,
    setFormData,
    editModalOpen,
    setEditModalOpen,
    userDetails,
    updateUserDetails,
    styles,
    open,
    setAnchorEl,
    anchorEl,
  } = obj;
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = userDetails;

  const handleMenuClick = async (menuItem) => {
    if (menuItem === "logout") {
      history.push("/");
      await dispatch(clearAccountReducerAction());
      await dispatch(clearProjectReducer());
      await dispatch(clearCallSettingReducerAction());
      await dispatch(clearAudienceReducer());
      await dispatch(logoutAction(history));
      await dispatch(getEncryptionPublicKeyAction());
      await dispatch(clearCampaignReducerAction());
      localStorage.removeItem("passwordReset");
    }
    if (menuItem === "edit") {
      setEditModalOpen(true);
      if (userDetails) {
        setFormData(userDetails);
      }
    }
    setAnchorEl(null);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        className="flex-xy-center"
        sx={{
          position: "relative",
        }}
      >
        <Box
          className="email-container"
          onClick={handleOpenMenu}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!open && (
            <IconButton style={styles.mailStyle} aria-label="Open user menu">
              {email?.charAt(0)?.toUpperCase()}
            </IconButton>
          )}
          {open && (
            <>
              <IconButton style={styles.mailStyle}>
                {email?.charAt(0)?.toUpperCase()}
              </IconButton>
              <Typography className="font-14-lightgrey">
                {shortenString(email, 18)}
              </Typography>
              <IconButton
                onClick={handleOpenMenu}
                sx={{
                  marginLeft: "8px",
                  color: "#9E9FA1",
                }}
                aria-label="More options"
              >
                <MoreVertIcon />
              </IconButton>
            </>
          )}
        </Box>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              minWidth: "150px",
              zIndex: 1300,
              marginLeft: "30px",
            },
            elevation: 3,
          }}
        >
          <MenuItem
            sx={{ py: 1.5 }}
            onClick={() => handleMenuClick("edit")}
            className="flex-align-center email-edit-option"
          >
            <Box className="flex-align-center" gap="8px" width="100%">
              <i className="fas fa-edit"></i> Edit Profile
            </Box>
          </MenuItem>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <MenuItem
            onClick={() => handleMenuClick("logout")}
            className="flex-align-center"
            sx={{
              color: "#555555",
              gap: "8px",
              fontSize: "13px",
              py: 1.5,
            }}
          >
            <Box className="flex-align-center" gap="8px" width="100%">
              <i className="fas fa-sign-out-alt"></i> Log out
            </Box>
          </MenuItem>
        </Popover>
      </Box>
      {editModalOpen && (
        <UpdateProfile
          open={open}
          formData={formData}
          setFormData={setFormData}
          updateUserDetails={updateUserDetails}
          handleModalClose={() => setEditModalOpen(!editModalOpen)}
        />
      )}
    </>
  );
};

export default EmailMenu;
