import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import arrowBackIcon, {
  ReactComponent as ArrowBackIcon,
} from "../../../assets/icons/arrow-back.svg";
import { ReactComponent as EmailSuccessIcon } from "../../../assets/icons/email-success.svg";
import { swalErrorMessage } from "../../../Constant/swalMessage";
import { resetPasswordAction } from "../Actions/CommonActions";
import BaseButton from "../Common/Buttons/BaseButton";
import { LoadingSlides } from "../CommonComponents/Common";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";
import CommonPopup from "../CommonComponents/PopUpCards/CommonPopup";
import { ReactComponent as SuccessIcon } from "../../../assets/Images/SuccessProjectIcon.svg";
import {
  getDecryptedItem,
  setEncryptedItem,
} from "../../../utils/localStorageHelper";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [spin, setSpin] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [attempt, setAttempt] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [passwordPopup, setPasswordPopup] = useState(false);

  useEffect(() => {
    const storedData = getDecryptedItem("passwordReset") || {};
    if (storedData.email === email) {
      setAttempt(storedData.attempt);
      if (storedData.lockoutUntil > Date.now()) {
        setTimeLeft(Math.floor((storedData.lockoutUntil - Date.now()) / 1000));
      } else {
        setTimeLeft(0);
      }
    } else {
      setAttempt(0);
      setTimeLeft(0);
    }
  }, [email]);

  useEffect(() => {
    if (timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            let storedData = getDecryptedItem("passwordReset") || {};
            if (storedData?.email) {
              setEncryptedItem("passwordReset", {
                ...storedData,
                lockoutUntil: 0,
              });
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timeLeft, email]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setSpin(true);

    if (!email) {
      swalErrorMessage("Please enter a valid email");
      setSpin(false);
      return;
    }

    const storedData = getDecryptedItem("passwordReset") || {};
    let userData = storedData.find((user) => user.email === email);
    let newAttempt = storedData.email === email ? storedData.attempt + 1 : 1;

    let res = await dispatch(resetPasswordAction({ email: email.trim() }));
    if (!res) {
      setEmailSent(false);
    } else {
      setEmailSent(true);
      setPasswordPopup(true);

      let lockoutDuration =
        newAttempt === 1 ? 90 : newAttempt === 2 ? 120 : 3600;
      const lockoutUntil = Date.now() + lockoutDuration * 1000;

      if (userData) {
        userData.attempt = newAttempt;
        userData.lockoutUntil = lockoutUntil;
      } else {
        storedData.push({
          email: email.trim(),
          attempt: newAttempt,
          lockoutUntil,
        });
      }
      setEncryptedItem("passwordReset", {
        email: email.trim(),
        attempt: newAttempt,
        lockoutUntil,
      });

      localStorage.setItem("passwordReset", JSON.stringify(storedData));
      setAttempt(newAttempt);
      setTimeLeft(lockoutDuration);
    }
    setSpin(false);
  };

  const togglePasswordPopup = () => {
    setPasswordPopup((prev) => !prev);
  };

  const handleBack = () => {
    setEmailSent(false);
    setEmail("");
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value.trim().toLowerCase();
    setEmail(newEmail);

    const storedData = getDecryptedItem("passwordReset") || {};

    if (storedData.email !== newEmail) {
      setAttempt(0);
      setTimeLeft(0);
    } else if (storedData.lockoutUntil > Date.now()) {
      setAttempt(storedData.attempt);
      setTimeLeft(Math.floor((storedData.lockoutUntil - Date.now()) / 1000));
    }
  };

  return (
    <Box className="main-container">
      {emailSent ? (
        <Box className="flex-column-center">
          <EmailSuccessIcon />
          <Typography className="font-18-700-grey" gutterBottom>
            Verification Email Sent
          </Typography>
          <Typography className="font-13-400-grey" gutterBottom>
            We sent a password reset mail to{" "}
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {email}
            </span>
          </Typography>
          <Typography className="font-13-400-grey" mb={2}>
            Please check your mail
          </Typography>
          <BaseButton
            title="Go Back"
            handleClick={handleBack}
            startIcon={<ArrowBackIcon />}
            styles={{ color: "#1A1D23", fontSize: 14, fontWeight: 400 }}
          />
          {passwordPopup && (
            <CommonPopup
              message={`Verification Email has been sent to your Email Id, Please verify to continue, if not received please check once on spam folder`}
              show={passwordPopup}
              toggle={togglePasswordPopup}
              Icon={SuccessIcon}
              heading=""
            />
          )}
        </Box>
      ) : (
        <>
          <Typography className="font-28-700-grey ">
            Forgot Password?
          </Typography>
          <Typography className="font-14-grey2" mb={2}>
            No worries we will send you a reset link
          </Typography>
          <Box mb={2}>
            <Typography className="font-14-grey mb-1">Email</Typography>
            <TextField
              name="email"
              value={email?.toLowerCase()}
              onChange={(e) => {
                setEmail(e.target.value.trim().toLowerCase());
              }}
              variant="outlined"
              fullWidth
              placeholder="Enter your registered email"
              sx={commonStyles.inputField}
            />
          </Box>

          <Button
            onClick={handleResetPassword}
            disabled={spin || attempt > 3 || timeLeft > 0}
            variant="contained"
            sx={commonStyles.loginButton}
            fullWidth
          >
            {spin ? <LoadingSlides bgColor="white" /> : "Reset Password"}
          </Button>
          {timeLeft > 0 && (
            <Typography textAlign="center" mt={2} color="error">
              Please wait {formatTime(timeLeft)} min. before retrying.
            </Typography>
          )}
          <Typography
            className="font-14-400-dark-grey"
            textAlign="center"
            mt={3}
          >
            <Box
              component={"span"}
              onClick={() => history.push("/login")}
              className="flex-xy-center"
              sx={{
                textDecoration: "none",
                color: "#1A1D23",
                cursor: "pointer",
              }}
            >
              <img
                src={arrowBackIcon}
                alt="arrow back"
                style={{ marginRight: 4 }}
              />
              Back to login
            </Box>
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ForgotPassword;
