import { axiosClient } from "../../../../Constant/apiConstant";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../../Constant/swalMessage";
import { setEncryptedItem } from "../../../../utils/localStorageHelper";
import {
  addWhatsappService,
  buyPhoneNumberService,
  checkGhlConnectionService,
  createPaymentOrderService,
  createTrunkService,
  createWhatsappTemplateService,
  getAllWhatsappService,
  getAlreadyAssignedNumberService,
  getConstantDataService,
  getCountriesListService,
  getDashboardChartService,
  getDashboardDataService,
  getEncryptionPublicKeySerivce,
  getPhoneNumberService,
  getWhatsappDetailByIdService,
  getWhiteLabelDetailsSerivce,
  paymentHistoryCaptureService,
  profileCheckService,
  saveTwillioSidService,
  setDefaultGatewayForAccountService,
  stripePaymentService,
  updateStripeAccountService,
  validateAccountService,
  verifyOrderService,
} from "../../services/commonServices";
import { AccountsType } from "./Account.type";

export const saveSelectedAccount = (obj) => async (dispatch) => {
  setEncryptedItem("selectedAccount", obj)
  await dispatch({
    type: AccountsType.SELECT_ACCOUNT,
    payload: obj,
  });
};

export const changeMenu = (obj) => async (dispatch) => {
  await dispatch({
    type: AccountsType.CHANGE_MENU,
    payload: obj,
  });
};

export const getConstantData = () => async (dispatch) => {
  let res = await getConstantDataService();
  if (res?.status === 200) {
    await dispatch({
      type: AccountsType.GET_CONSTANT_DATA,
      payload: res?.data[0],
    });
    return res.status;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const logoutAction = (history) => async (dispatch) => {
  localStorage.clear();
  history.push("/");
  axiosClient.defaults.headers.common["Authorization"] = null;
  axiosClient.defaults.headers.common["dbUrl"] = null;
};

export const createPaymentOrderAction = (data) => async () => {
  let res = await createPaymentOrderService(data);
  if (res?.status === 200) {
    return res.data;
  } else {
    swalErrorMessage("Something went wrong");
  }
};

export const profileCheckAction = (data) => async () => {
  let res = await profileCheckService(data);
  if (res?.status === 200) {
    return res.data;
  } else {
    swalErrorMessage("Something went wrong");
  }
};

export const verifyPaymentOrderAction = (data) => async () => {
  let res = await verifyOrderService(data);
  if (res?.status === 200) {
    swalSuccessMessage("Topup Account Successful");
    return res;
  } else {
    swalErrorMessage("Something went wrong");
  }
};

export const getDashboardDataAction = (data) => async (dispatch) => {
  let res = await getDashboardDataService(data);
  if (res?.status === 200) {
    await dispatch({
      type: AccountsType.GET_DASHBOARD_DATA,
      payload: res?.data,
    });
    return res.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getDashboardChartAction = (data) => async (dispatch) => {
  let res = await getDashboardChartService(data);
  if (res?.status === 200) {
    await dispatch({
      type: AccountsType.GET_CHART_DATA,
      payload: res?.data,
    });
    return res.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const stripePaymentOrderAction = (data) => async () => {
  let res = await stripePaymentService(data);
  if (res?.status === 200) {
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const updateStripeAccountAction = (data) => async () => {
  let res = await updateStripeAccountService(data);
  if (res.status === 200) {
    return res.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const clearAccountReducerAction = () => async (dispatch) => {
  await dispatch({
    type: AccountsType.CLEAR_ACCOUNT_REDUCER,
    payload: {},
  });
};

export const checkValidationAccountAction = (obj) => async (dispatch) => {
  let res = await validateAccountService(obj);
  if (res?.status === 200) {
    return res.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getWhitelabelAction = () => async (dispatch) => {
  let res = await getWhiteLabelDetailsSerivce();
  if (res?.status === 200) {
    await dispatch({
      type: AccountsType.GET_WHITELABEL_DETAILS,
      payload: { data: res?.data?.frontendDetails || {}, fetched: true },
    });
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getEncryptionPublicKeyAction = () => async (dispatch) => {
  let res = await getEncryptionPublicKeySerivce();
  if (res?.status === 200) {    
    await dispatch({
      type: AccountsType.GET_ENCRYPTION_PUBLIC_KEY,
      payload: { data: res?.data?.[0]?.publicKey },
    });
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const saveTwillioSidAction = (obj) => async (dispatch) => {
  let res = await saveTwillioSidService(obj);
  if (res) {
    swalSuccessMessage("Created Successfully");
  }
};

export const checkGhlConnection = () => async () => {
  let res = await checkGhlConnectionService();
  if (res) {
    return res;
  }
};
export const paymentHistoryCaptureAction = () => async (dispatch) => {
  let res = await paymentHistoryCaptureService();
  if (res?.status === 200) {
  }
};

export const createTrunkAction = (selectedAuth, obj) => async (dispatch) => {
  let res = await createTrunkService(selectedAuth, obj);
  if (res) {
    swalSuccessMessage("Created Successfully");
  }
};

export const getCountriesListAction = () => async () => {
  let res = await getCountriesListService();
  if (res) {
    return res;
  }
};
export const getPhoneNumbersAction = (friendly_name, obj) => async () => {
  let res = await getPhoneNumberService(friendly_name, obj);
  if (res) {
    return res;
  }
};

export const buyPhoneNumberAction = (obj) => async () => {
  let res = await buyPhoneNumberService(obj);
  if (res) {
    return res;
  }
};
export const getAlreadyAssignedNumberAction = (friendly_name) => async () => {
  let res = await getAlreadyAssignedNumberService(friendly_name);
  if (res) {
    return res;
  }
};

export const addNotificationAction = (text, variant) => async (dispatch) => {
  dispatch({
    type: AccountsType.ADD_NOTIFICATION,
    text: text,
    variant: variant,
  });
};

export const removeNotificationAction = (id) => async (dispatch) => {
  dispatch({ type: AccountsType.REMOVE_NOTIFICATION, id: id });
};

export const setDefaultGatewayForAccount = (obj) => async (dispatch) => {
  let res = await setDefaultGatewayForAccountService(obj);
  if (res.status === 200) {
    dispatch(addNotificationAction(`${"Successfully Saved"}`, "success"));
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const addWhatsappAccount = (obj) => async () => {
  let res = await addWhatsappService(obj);
  console.log("rere", res);
};

export const getAllWhatsappAccountsAction = (ids) => async () => {
  let res = await getAllWhatsappService(ids);

  return res;
};
export const getWhatsappAccountByIdAction = (id) => async () => {
  let res = await getWhatsappDetailByIdService(id);
  console.log("rere", res);
};

export const createWhatsappTemplateAction = (id, obj) => async () => {
  let res = await createWhatsappTemplateService(id, obj);

  console.log("rere", res);
};
