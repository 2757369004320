import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../../../Constant/swalMessage";
import { addNotificationAction } from "../../../Store/Account/Account.action";
import {
  callAgentService,
  chatTestAgentService,
  deleteAgentService,
  editAgentService,
  generatePromptService,
  generateScenariosService,
  generateVoiceService,
  getAgentService,
  getAllAgentService,
  getChatIdAgentService,
  getPossibleObjectionsAgentService,
  handleChatAgentService,
  handleEditChatAgentService,
  handleOnboardingChatAgentService,
  importAgentService,
  saveAgentService,
  setDeafaultAgentService,
  testAgentProgressService,
} from "../../../services/agent.services";

import { AgentType } from "./Agent.type";
export const getAgentAction = (id) => async (dispatch) => {
  let res = await getAgentService(id);
  if (res) {
    await dispatch({
      type: AgentType.GET_AGENT_DATA,
      payload: {
        data: res?.data || [],
      },
    });
  }
};
export const getAllAgentAction = () => async (dispatch) => {
  let res = await getAllAgentService();
  if (res) {
    await dispatch({
      type: AgentType.GET_ALL_AGENT,
      payload: {
        data: res?.data || [],
      },
    });
  }
};

export const importAgentAction = (obj) => async () => {
  let res = await importAgentService(obj);
  if (res?.status === 200) {
   return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const editAgentAction =
  (id, data, swalShow = true) =>
  async (dispatch) => {
    const res = await editAgentService(id, data);
    if (res?.status === 200) {
      if (swalShow) swalSuccessMessage(res.msg);
      return res.status;
    } else {
      swalErrorMessage(res?.errMsg);
    }
  };
  
export const saveAgentAction =
  (obj, swal = true) =>
  async () => {
    let res = await saveAgentService(obj);
    if (res?.status === 200) {
      return res;
    } else {
      swalErrorMessage(res?.errMsg);
    }
  };

export const deleteAgentAction = (id) => async () => {
  let res = await deleteAgentService(id);
  if (res?.status === 200) {
    // swalSuccessMessage(res?.msg);
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const editDataReducerActiom = (obj) => async (dispatch) => {
  await dispatch({
    type: AgentType.EDIT_AGENT_DATA,
    payload: obj,
  });
};

export const setDefaultAgentAction = (data, id) => async () => {
  let res = await setDeafaultAgentService(data, id);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getPossibleObjectionsAgentAction = (data) => async () => {
  let res = await getPossibleObjectionsAgentService(data);
  if (res) {
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getChatIdAgentAction = (data) => async () => {
  let res = await getChatIdAgentService(data);
  if (res) {
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const handleChatAgentAction = (data) => async () => {
  let res = await handleChatAgentService(data);
  if (res) {
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};
export const handleOnbiardingChatAgentAction = (data) => async () => {
  let res = await handleOnboardingChatAgentService(data);
  if (res) {
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const generatePromptAction = (data) => async () => {
  let res = await generatePromptService(data);
  if (res) {
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const generateVoiceAction = (data) => async () => {
  let res = await generateVoiceService(data);
  if (res) {
    return res;
  } else {
    swalErrorMessage(
      "Voice is not available at the moment, please try with another voice"
    );
  }
};

export const handleEditChatAgentAction = (data, id) => async (dispatch) => {
  let res = await handleEditChatAgentService(data, id);
  if (res) {
    dispatch(addNotificationAction("Conversation Updated", "success"));
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const callAgentAction =
  (obj, swal = true) =>
  async () => {
    let res = await callAgentService(obj);
    if (res?.status === 200) {
      if (swal) {
        swalSuccessMessage(res.msg);
      }
      return res;
    } else {
      swalErrorMessage(res?.errMsg);
    }
  };

export const generateScenariosAction = (obj) => async () => {
  const res = await generateScenariosService(obj);  
  if (res) {
    return res?.test_cases;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const chatTestAgentAction = (obj) => async () => {
  const res = await chatTestAgentService(obj);
  if (res) {
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const testAgentProgressAction = (id) => async () => {
  const res = await testAgentProgressService(id);

  if (res) {
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};