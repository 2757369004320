import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { redirectAction } from "../Actions/CommonActions";
import { getDecryptedItem } from "../../../utils/localStorageHelper";

const RedirectConnectionPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const locSearch = new URLSearchParams(location.search);
  let code = locSearch.get("code");
  let authType = locSearch.get("authType");
  const projectId = getDecryptedItem("projectId");

  useEffect(() => {
    const init = async () => {
      if (authType === "gmail") {
        await dispatch(redirectAction(code, projectId, authType));
      } else {
        await dispatch(redirectAction(code, projectId, authType));
      }
      history.push(`account/project/${projectId}/integrations`);
    };
    init();
  }, []);
  return (
    <Box display={"flex"} justifyContent={"center"} mt={5}>
      <Box className="font-14-600-grey">Redirecting...</Box>
    </Box>
  );
};

export default RedirectConnectionPage;
